import {apolloPlatformAxiosInstance as axiosInstance} from './apolloPlatformAxiosInstance';

/**
 * Notifications API
 */

export const apiGetNotificationsList = (limit: number, offset: number, type: string) => {
  return axiosInstance.get(`/notifications/type/${type}/`, {params: {limit, offset}});
};

export const apiMarkAllNotificationsAsRead = () => {
  return axiosInstance.put('/notifications/read/');
};

export const apiMarkNotificationAsRead = (slug: number) => {
  return axiosInstance.put(`/notifications/read/${slug}/`);
};
