export function scrollTop() {
  const element = document.querySelector('#scrolling');
  if (element !== null) element.scrollTop = 0;
}

export function scrollIntoView(
  element: HTMLElement,
  block: ScrollLogicalPosition = 'nearest',
): void {
  if (element) element.scrollIntoView({block});
}
