<template>
  <div id="default-layout" class="d-flex flex-column h-100 overflow-hidden">
    <slot name="header" />

    <!-- Content area -->
    <div id="content-area" class="d-flex overflow-hidden h-100">
      <slot name="sidebar" />
      <main
        id="scrolling"
        class="flex-grow-1 overflow-x-hidden h-100 py-3 pe-3 px-lg-3 custom-scrollbar"
      >
        <slot name="main" />
      </main>
    </div>
  </div>
</template>
