import qs from 'qs';
import axios from 'axios';
import appConfig from '@/config';
import {useMsalAuthentication} from './useMsalAuthentication';
import {defaultRequest} from '@/authConfig';
import {combineRequest} from '@/api/requestCombiner';

// by convention, composable function names start with "use"
export function useApiInstance() {
  const {acquireToken} = useMsalAuthentication(defaultRequest);
  function axiosApiInstance(config) {
    const userSettingsStore = useUserSettingsStore();
    const customersStore = useCustomersStore();

    const instance = axios.create({
      baseURL: appConfig.BACKEND_API,
      headers: {
        Accept: 'application/json; version=1.0',
        'Content-Type': 'application/json',
        'Accept-Language': userSettingsStore.userSettings.language,
      },
      paramsSerializer: {
        serialize: (params) => qs.stringify(params, {encode: false}),
      },
    });

    // Request interceptor for API calls
    instance.interceptors.request.use(
      async (config) => {
        const tokenResponse = await acquireToken();
        config.headers.Authorization = `Bearer ${tokenResponse.accessToken}`;
        config.headers['Accept-Language'] = userSettingsStore.userSettings.language;

        if (customersStore.selectedCustomer !== null) {
          config.headers['Sphere-Customer-UID'] =
            customersStore.selectedCustomer.customer.debtor_number;
        }
        config.paramsSerializer = (params) => qs.stringify(params, {encode: false});
        return config;
      },
      (error) => {
        Promise.reject(error);
      },
    );

    instance.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        return Promise.reject(error);
      },
    );

    return instance(config);
  }

  // TODO: Figure out where to put this
  async function fetchAllFromApi(url) {
    const allItems = [];
    async function fetchApi(url, params) {
      let config = {
        method: 'GET',
        url: url,
        params: params,
      };
      await axiosApiInstance(config).then(async (response) => {
        allItems.push(...response.data.results);
        if (!Object.prototype.hasOwnProperty.call(response.data, 'next')) {
          return;
        }
        let nextUrl = response.data.next;
        if (nextUrl !== null && nextUrl.includes('cursor=')) {
          await fetchApi(nextUrl);
        }
      });
    }
    await combineRequest(`fetchAll:${url}`, () => fetchApi(url, {}));
    return allItems;
  }

  return {axiosApiInstance, fetchAllFromApi};
}
