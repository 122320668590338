import {createApp} from 'vue';
import {createPinia} from 'pinia';
import {EventType} from '@azure/msal-browser';
import VueGridLayout from 'vue-grid-layout';
import App from './App.vue';
import router from './router';
import {msalPlugin} from './plugins/msalPlugin';
import {msalInstance} from './authConfig';
import '@popperjs/core'; // Required by Bootstrap

import '@/scss/ekco.scss';

// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md#issues-caused-by-the-redirecturi-page
// >If your redirectUri is removing or manipulating this hash or
// >navigating to a different page before MSAL has extracted it you will
// >receive this timeout error.
// > ...
// >We recommend using a blank page as your redirectUri for silent and
// >popup flows to ensure none of these things can occur.
//
// We accomplish this by not loading the Vue app when we're inside an iframe.

function inIframe() {
  try {
    return window.self !== window.top;
  } catch {
    return true;
  }
}

if (!inIframe()) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }
  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  const pinia = createPinia();

  // Make router accessible in stores
  pinia.use(({store}) => {
    store.router = markRaw(router);
  });

  const app = createApp(App);
  app.use(pinia);
  app.use(msalPlugin, msalInstance);
  app.use(VueGridLayout);
  app.use(router);
  router.isReady().then(() => {
    // Waiting for the router to be ready prevents race conditions when returning from a loginRedirect or acquireTokenRedirect
    app.mount('#app');
  });
}
