import {breakpointsBootstrapV5, useBreakpoints} from '@vueuse/core';

export function useBsBreakpoints() {
  const breakpoints = useBreakpoints({
    ...breakpointsBootstrapV5,
    xxxl: 1900, // Requested by design team
    macBook: 3024, // Requested by Slobo
  });
  const activeBreakpoint = breakpoints.active();
  const isMacBook = breakpoints.greaterOrEqual('macBook');
  const isMobile = breakpoints.smallerOrEqual('sm');
  const isTablet = breakpoints.between('sm', 'lg');
  return {breakpoints, activeBreakpoint, isMacBook, isMobile, isTablet};
}
