export function useLocalStorage() {
  const userStorage = useUserStore();
  /**
   * Gets an item in local storage
   */
  const getItem = <Type>(itemName: string): Type | null => {
    const user_id = userStorage.user?.id;
    const item: string | null = localStorage.getItem(
      `${window.location.host}_${user_id}_${itemName}`,
    );
    return item !== null ? JSON.parse(item) : null;
  };
  /**
   * Sets an item in local storage
   */
  const setItem = (key: string, value: unknown) => {
    const user_id = userStorage.user?.id;
    if (user_id !== undefined) {
      localStorage.setItem(`${window.location.host}_${user_id}_${key}`, JSON.stringify(value));
    }
  };
  /**
   * Removes an item from local storage
   */
  const removeItem = (key: string) => {
    const user_id = userStorage.user?.id;
    localStorage.removeItem(`${window.location.host}_${user_id}_${key}`);
  };
  return {getItem, setItem, removeItem};
}
