<template>
  <div class="content" style="max-width: 600px; margin: auto">
    <h3 data-testid="error-page.message.title.h3" style="padding-bottom: 10px">
      {{ translateText('Page not found') }}
    </h3>
    <p>{{ translateText('Page not found') }}.</p>
    <p>
      <span>
        {{ translateText('Let us know using the  ') }}
        <span class="text-primary" style="cursor: pointer" @click="feedbackOpen">{{
          translateText('feedback')
        }}</span>
        {{ translateText(' function if you think this is a mistake') }}.
      </span>
    </p>
    <p>
      <router-link to="/">{{ translateText('Return to the homepage') }}.</router-link>
    </p>
  </div>
</template>

<script setup lang="ts">
import translate from '@/translate';
import {
  feedbackOpenKey,
  FeedbackOpen,
} from '@/components/shared/molecules/FeedbackSlideIn/injectionKeys';

const userSettingsStore = useUserSettingsStore();
const language = computed(() => userSettingsStore.userSettings.language);
const translateText = (input: string) => translate.translate(language.value, input);
const feedbackOpen = inject<FeedbackOpen>(feedbackOpenKey) as FeedbackOpen;
</script>

<style scoped lang="scss">
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.content {
  animation: fadeIn 0.5s ease-in;
  animation-iteration-count: 1;
}
</style>
