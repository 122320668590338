<template>
  <NotificationDropdownPresentational
    v-model:activeTab="activeTab"
    :notifications="notifications.data || []"
    :tabs="tabs"
    :unread-notifications="unreadCount"
    :loading="loading"
    :count="pageCount"
    :page="currentPage"
    @mark-all-as-read="notificationsStore.markAllNotificationsAsRead"
    @mark-item-as-read="dismissNotification"
    @open-item="(item: Notification) => redirectToUrl(item)"
    @update:page="
      (newPage: number) => {
        pageOffsetOverride = Math.max(0, (newPage - 1) * pageLimit);
        fetchNotifications(pageLimit, pageOffsetOverride);
      }
    "
    @update:active-tab="(tab: 'Unread' | 'All Notifications') => (activeTab = tab)"
  />
</template>
<script lang="ts" setup>
import {Notification} from '@/stores/apolloPlatform/notifications/notifications';
const notificationsStore = useNotificationsStore();
const userStore = useUserStore();

const router = useRouter();

const tabs = ['Unread', 'All Notifications'];
const activeTab = ref<'Unread' | 'All Notifications'>('Unread');

const defaultResponse = {
  data: [],
  limit: 25,
  offset: 0,
  data_type: activeTab.value,
  notification_count: 0,
  unread_count: 0,
};

const notifications = computed(
  () => notificationsStore.notifications[activeTab.value] || defaultResponse,
);
const unreadCount = computed(() => notifications.value.unread_count || 0);
const totalCount = computed(() => notifications.value.notification_count);
const username = computed(() => userStore.user?.username);

const loading = ref(false);
const pageLimit = computed(() => notifications.value.limit);
const pageOffset = computed(() => notifications.value.offset);
const pageOffsetOverride = ref(0);
const pageCount = computed(() => {
  const total = activeTab.value === 'Unread' ? unreadCount.value : totalCount.value;
  return Math.ceil(total / notifications.value.limit);
});
const currentPage = computed(() => {
  return Math.floor(pageOffset.value / pageLimit.value) + 1;
});

const dismissNotification = async (id: number) => {
  if (username.value === undefined) {
    return;
  }
  if (notifications.value?.data?.find((notification) => notification.id === id)?.unread) {
    await notificationsStore.markNotificationAsRead(id);
  }
};

const redirectToUrl = async (notification: Notification) => {
  if (username.value === undefined || notification.data?.url === undefined) {
    return;
  }
  router.push(notification.data?.url);
  await notificationsStore.markNotificationAsRead(notification.id);
};

const fetchNotifications = async (limit: number = 25, offset: number = 0) => {
  if (username.value === undefined) {
    throw new Error('No username found');
  }
  try {
    loading.value = true;
    await notificationsStore.fetchNotificationsWithResync(activeTab.value, limit, offset);
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
};

watch(activeTab, async () => {
  if (!notificationsStore.notifications[activeTab.value]) {
    await fetchNotifications();
  }
  notificationsStore.selectedType = activeTab.value;
});

let interval: number;
onMounted(() => {
  interval = window.setInterval(
    async () => {
      await notificationsStore.fetchNotificationsWithResync(
        activeTab.value,
        pageLimit.value,
        pageOffset.value,
      );
    },
    1000 * 60 * 2,
  );
});

onBeforeUnmount(() => {
  if (interval) window.clearInterval(interval);
});
</script>
