interface ErrorObject {
  type: string;
}

export const useErrorStore = defineStore('errorStore', () => {
  const error = ref<ErrorObject | undefined>(undefined);
  const hasError = computed(() => error.value !== undefined);

  const setError = (type: string) => {
    error.value = {type: type};
  };
  const setDetailedError = (newError: ErrorObject) => {
    error.value = newError;
  };

  const clearError = () => {
    error.value = undefined;
  };

  return {
    error,
    hasError,
    setError,
    setDetailedError,
    clearError,
  };
});
