<template>
  <div class="form-check" :class="{'form-check-inline': inline}">
    <input
      :id="id"
      v-model="model"
      :value="value"
      class="form-check-input"
      type="radio"
      :name="name"
      :disabled="disabled"
      :required="required"
    />
    <label :for="id" class="form-check-label ps-1 text-nowrap">
      {{ label }}
    </label>
  </div>
</template>
<script lang="ts" setup>
interface Props {
  id: string;
  name: string;
  disabled?: boolean;
  label?: string;
  modelValue: string;
  value: string;
  inline?: boolean;
  required?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  label: '',
  inline: false,
  required: false,
});

const emit = defineEmits<{'update:modelValue': [value: string]}>();

const model = computed({
  get: () => props.modelValue,
  set: (value: string) => emit('update:modelValue', value),
});
</script>
