<template>
  <div>
    <div v-if="language === 'en-US'" class="px-3">
      <div class="card m-3 p-3">
        <h1>Cookie Policy - Ekco Sphere</h1>
        <p>
          Our website uses cookies to distinguish you from other users of our website. This helps us
          to provide you with a good experience when you browse our website and also allows us to
          improve our site.
        </p>
        <p>
          We may update this Cookie Policy from time to time. Please review the "Last Updated" note
          at the bottom of this page. Changes in this Cookie Policy will be effective from the date
          that it is available on our website.
        </p>
        <h2>1. What are cookies?</h2>
        <p>
          Cookies are small pieces of information, stored in simple text files, placed on your
          computer or internet enabled device by a website. Typically, cookies are used to ease
          navigation through the website. Cookies can be read by the website on your subsequent
          visits. In addition, the information stored in a cookie may relate to your browsing habits
          on the web page, or a unique identification number so that the website can remember you on
          your return visit.
        </p>
        <p>
          We use first party and third-party cookies to make our website easier to use and to
          support its functionality. Cookies also provide us with information on how our website is
          used so that we can ensure it is up to date, relevant and as error free as possible.
        </p>
        <p>
          By using our website, you are agreeing to our use of strictly necessary cookies and any
          other cookies you agree to on our cookie notice. Where use of this website results in
          Personal Information being collected through cookies, Ekco's Privacy Policy applies
          alongside this Policy.
        </p>
        <h2>2. User Rights</h2>
        <p>
          Strictly necessary cookies are always on, but you can accept or reject the other types of
          cookies on our website. If you choose not to accept all cookies, your experience of our
          website will be affected by this, and you may not be able to access certain services or
          functions on the website. You can at any time change or withdraw from the cookie
          declaration on our website (see below).
        </p>
        <h2>3. Cookie Types</h2>
        <p>We use the following categories of cookies:</p>
        <ol type="a">
          <li>
            <b>Basic Operations Cookies:</b> these cookies help make our website usable by enabling
            basic functions like page navigation and access to secure areas of the website. The
            website cannot function properly without these cookies.
          </li>
          <li>
            <b>Content Personalisation Cookies:</b> enables Ekco to remember information that
            changes the way our websites behave or look, like your preferred language or the region
            that you are in.
          </li>
          <li>
            <b>Site Optimisation Cookies:</b> help Ekco to understand how visitors interact with our
            websites by collecting and reporting information anonymously.
          </li>
          <li>
            <b>Ad Personalisation Cookies:</b> are used to track visitors across our websites. The
            intention is to display ads that are relevant and engaging for the individual user and
            thereby more valuable for publishers and third-party advertisers.
          </li>
        </ol>
        <p>
          You can find more information about the individual cookies we use in the table in our
          cookie declaration.
        </p>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Cookie Name</th>
              <th>Purpose</th>
              <th>Will be stored for</th>
              <th>Learn more</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Google Tag Manager</td>
              <td>
                Google Tag Manager (also known as GTM) is an "online tool" that allows us to
                implement and manage various marketing and analytics tags on our website.
              </td>
              <td>2 years</td>
              <td>For more information, read Google's overview of data privacy and protection.</td>
            </tr>
            <tr>
              <td>Google Analytics</td>
              <td>
                These cookies are used to collect information about how visitors use our website. We
                use the information to compile reports and to help improve the website. The cookies
                collect information in a way that does not directly identify anyone, including the
                number of visitors to the website and blog, where visitors came from, and which
                pages they visited.
              </td>
              <td>2 years</td>
              <td>For more information, read Google's overview of data privacy and protection.</td>
            </tr>
            <tr>
              <td>Hubspot</td>
              <td>
                We use HubSpot to store your data for the purpose of performing marketing tasks.
              </td>
              <td>13 months</td>
              <td>For more information, please read Hubspot's Privacy Policy.</td>
            </tr>
            <tr>
              <td>Mailchimp</td>
              <td>
                We use MailChimp to manage our newsletter and other email lists. The messages we
                send through MailChimp do not use third-party tracking, except in MailChimp's own
                analytics, which can indicate how many subscribers open a message or click on the
                links in the message.
              </td>
              <td>Indefinite - until the recipient of the email deletes the email.</td>
              <td>For more information, please read MailChimp's privacy policy.</td>
            </tr>
            <tr>
              <td>Facebook Pixel</td>
              <td>
                These cookies are placed by Facebook. It allows us to measure, optimize and create
                audiences for advertising campaigns offered on Facebook. In particular, it allows us
                to see how our users move between devices when visiting our website and Facebook.
                This helps us to ensure that our Facebook advertising is seen by our users who are
                most likely to be interested in such advertising, by analysing what content a user
                has viewed on our website and what content a user has interacted with.
              </td>
              <td>
                <p>3 months if the 'keep me logged in' option is disabled.</p>
                <p>2 years if the 'keep me logged in' option is enabled.</p>
              </td>
              <td>For more information, please read Facebook's Privacy Policy</td>
            </tr>
            <tr>
              <td>LinkedIn Insight Tag</td>
              <td>
                These cookies are placed by LinkedIn. It allows us to measure, optimize, and create
                audiences for advertising campaigns offered on LinkedIn. In particular, it allows us
                to see how our users move between devices when visiting our website and LinkedIn.
                This helps us to ensure that our LinkedIn advertising is seen by our users who are
                most likely to be interested in such advertising, by analysing what content a user
                has viewed on our website and what content a user has interacted with.
              </td>
              <td>3 months</td>
              <td>For more information, please read LinkedIn's Privacy Policy</td>
            </tr>
            <tr>
              <td>Embed/v3/counters.gif</td>
              <td>
                This Cookie is set by HubSpot. It collects information about user preferences and/or
                interaction with web campaign content – This is used on the CRM campaign platform
                used by website owners for promoting events or products.
              </td>
              <td>Session</td>
              <td>
                For more information, see:
                <a
                  href="https://knowledge.hubspot.com/reports/what-cookies-does-hubspot-set-in-a-visitor-s-browser"
                  target="_blank"
                  >https://knowledge.hubspot.com/reports/what-cookies-does-hubspot-set-in-a-visitor-s-browser</a
                >
              </td>
            </tr>
            <tr>
              <td>hubspotutk</td>
              <td>
                This cookie keeps track of a visitor's identity. It is passed to the marketing
                platform HubSpot when submitting forms and is used in de-duplicating contacts.
              </td>
              <td>1 year</td>
              <td>
                For more information, see:
                <a
                  href="https://knowledge.hubspot.com/reports/what-cookies-does-hubspot-set-in-a-visitor-s-browser"
                  target="_blank"
                  >https://knowledge.hubspot.com/reports/what-cookies-does-hubspot-set-in-a-visitor-s-browser</a
                >
              </td>
            </tr>
            <tr>
              <td>__hstc</td>
              <td>
                This is the main cookie for tracking visitors. It contains the domain, utk, the
                first timestamp (first visit), the last timestamp (last visit), the current
                timestamp (this visit), and the session number (incremented for each subsequent
                session).
              </td>
              <td>13 months</td>
              <td>For more information, please read HubSpot's Privacy Policy</td>
            </tr>
            <tr>
              <td>__hssc</td>
              <td>
                This cookie keeps track of the sessions. This is used to determine if HubSpot needs
                to increment the session number and timestamps in the __hstc cookie. It contains the
                domain, viewCount (increments each pageView in a session), and the timestamp for
                starting the session.
              </td>
              <td>30 minutes</td>
              <td>For more information, please read HubSpot's Privacy Policy</td>
            </tr>
            <tr>
              <td>__hssrc</td>
              <td>
                When HubSpot modifies the session cookie, this cookie is also set to determine if
                the visitor has restarted their browser. If this cookie does not exist when HubSpot
                manages cookies, it is considered a new session.
              </td>
              <td>Until the end of the session</td>
              <td>For more information, please read HubSpot's Privacy Policy</td>
            </tr>
            <tr>
              <td>messagesUtk</td>
              <td>
                This cookie is used to recognize visitors who chat with us through the messaging
                tool. If we chat with a visitor who later returns to the website in the same cookie
                browser, the messaging tool will load his or her conversation history.
              </td>
              <td>13 months</td>
              <td>For more information, please read HubSpot's Privacy Policy</td>
            </tr>
            <tr>
              <td>__hs_opt_out</td>
              <td>
                This is an essential/necessary cookie that does not require consent. This cookie is
                used to remember that the visitor will not be asked to accept cookies again.
              </td>
              <td>13 months</td>
              <td>For more information, please read HubSpot's Privacy Policy</td>
            </tr>
            <tr>
              <td>__hs_do_not_track</td>
              <td>
                This is an essential/necessary cookie that does not require consent. This cookie can
                be set to prevent the tracking code from sending information to HubSpot. It still
                allows anonymized information to be sent to HubSpot.
              </td>
              <td>13 months</td>
              <td>For more information, please read HubSpot's Privacy Policy</td>
            </tr>
            <tr>
              <td>hs_ab_test</td>
              <td>
                This is an essential/necessary cookie that does not require consent. This cookie is
                used to consistently show visitors the same version of the landing page that they
                have seen before.
              </td>
              <td>End of the session</td>
              <td>For more information, please read HubSpot's Privacy Policy</td>
            </tr>
            <tr>
              <td>hs-messages-is-open</td>
              <td>
                This is an essential/necessary cookie that does not require consent. This cookie is
                used to determine and store whether the chat widget is open for future visits. It
                re-sets the widget to close it after 30 minutes of inactivity.
              </td>
              <td>30 minutes</td>
              <td>For more information, please read HubSpot's Privacy Policy</td>
            </tr>
            <tr>
              <td>hs-messages-disguise-welcome-message</td>
              <td>
                This is an essential/necessary cookie that does not require consent. This cookie is
                used to prevent the welcome message from appearing one day after it has been
                deleted.
              </td>
              <td>1 day</td>
              <td>For more information, please read HubSpot's Privacy Policy</td>
            </tr>
            <tr>
              <td>__hsmem</td>
              <td>
                This is an essential/necessary cookie that does not require consent. This cookie is
                set when visitors log in to a HubSpot-hosted site.
              </td>
              <td>1 year</td>
              <td>For more information, please read HubSpot's Privacy Policy</td>
            </tr>
            <tr>
              <td>TSxxxxxx</td>
              <td>
                <p>
                  TTGT sets an internal security cookie within the inbound converter system to apply
                  elements in the load balancer's security policy. This cookie is called the master
                  cookie. The master cookie is inserted into HTTP responses to client requests. The
                  main cookie serves the following functions:
                </p>
                <ul>
                  <li>used for validating domain cookies and qualifying subdomain cookies</li>
                  <li>recognizing session progress</li>
                  <li>validate the integrity of the frame or function cookie if it is used</li>
                </ul>
              </td>
              <td>End of the session</td>
              <td>For more information, please read TechTarget's Cookie Policy</td>
            </tr>
            <tr>
              <td>SessionUser_{site_id}</td>
              <td>
                <p>Set when a user first lands on a page.</p>
                <p>
                  Persists the Hotjar User ID which is unique to that site. Hotjar does not track
                  users across different sites.
                </p>
                <p>
                  Ensures data from subsequent visits to the same site are attributed to the same
                  user ID.
                </p>
              </td>
              <td>365 days</td>
              <td>For more information, please read the Hotjar Cookie Policy</td>
            </tr>
            <tr>
              <td>_hjHasCachedUserAttributes</td>
              <td>
                Enables us to know whether the data set in _hjUserAttributes Local Storage item is
                up to date or not.
              </td>
              <td>Session duration</td>
              <td>For more information, please read the Hotjar Cookie Policy</td>
            </tr>
            <tr>
              <td>_hjUserAttributesHash</td>
              <td>
                Enables us to know when any User Attribute has changed and needs to be updated.
              </td>
              <td>2 minutes duration, extended every 30 seconds.</td>
              <td>For more information, please read the Hotjar Cookie Policy</td>
            </tr>
            <tr>
              <td>_hjUserAttributes</td>
              <td>Stores User Attributes sent through the Hotjar Identify API.</td>
              <td>No explicit expiration</td>
              <td>For more information, please read the Hotjar Cookie Policy</td>
            </tr>
            <tr>
              <td>hjViewportId</td>
              <td>Stores user viewport details such as size and dimensions.</td>
              <td>Session duration</td>
              <td>For more information, please read the Hotjar Cookie Policy</td>
            </tr>
            <tr>
              <td>hjActiveViewportIds</td>
              <td>
                <p>Stores user active viewports IDs.</p>
                <p>
                  Stores an expirationTimestamp that is used to validate active viewports on script
                  initialization.
                </p>
              </td>
              <td></td>
              <td>For more information, please read the Hotjar Cookie Policy</td>
            </tr>
            <tr>
              <td>_hjSession_{site_id}</td>
              <td>
                <p>Holds current session data.</p>
                <p>
                  Ensures subsequent requests in the session window are attributed to the same
                  session.
                </p>
              </td>
              <td>30 minutes duration, extended on user activity.</td>
              <td>For more information, please read the Hotjar Cookie Policy</td>
            </tr>
            <tr>
              <td>_hjCookieTest</td>
              <td>
                <p>
                  Checks to see if the Hotjar Tracking Code can use cookies. If it can, a value of 1
                  is set.
                </p>
                <p>Deleted almost immediately after it is created.</p>
              </td>
              <td>Under 100ms duration, cookie expiration time set to session duration.</td>
              <td>For more information, please read the Hotjar Cookie Policy</td>
            </tr>
            <tr>
              <td>_hjLocalStorageTest</td>
              <td>
                <p>Checks if the Hotjar Tracking Code can use Local Storage.</p>
                <p>If it can, a value of 1 is set.</p>
                <p>
                  Data stored in _hjLocalStorageTest has no expiration time, but it is deleted
                  almost immediately after it is created.
                </p>
              </td>
              <td>Under 100ms duration.</td>
              <td>For more information, please read the Hotjar Cookie Policy</td>
            </tr>
            <tr>
              <td>_hjSessionStorageTest</td>
              <td>
                <p>
                  Checks if the Hotjar Tracking Code can use Session Storage. If it can, a value of
                  1 is set.
                </p>
                <p>
                  Data stored in _hjSessionStorageTest has no expiration time, but it is deleted
                  almost immediately after it is created.
                </p>
              </td>
              <td>Under 100ms duration.</td>
              <td>For more information, please read the Hotjar Cookie Policy</td>
            </tr>
            <tr>
              <td>_hjTLDTest</td>
              <td>
                <p>
                  We try to store the _hjTLDTest cookie for different URL substring alternatives
                  until it fails.
                </p>
                <p>
                  Enables us to try to determine the most generic cookie path to use, instead of
                  page hostname.
                </p>
                <p>It means that cookies can be shared across subdomains (where applicable).</p>
                <p>After this check, the cookie is removed.</p>
              </td>
              <td>Session duration.</td>
              <td>For more information, please read the Hotjar Cookie Policy</td>
            </tr>
            <tr>
              <td>hjClosedSurveyInvites</td>
              <td>
                <p>Set when a user interacts with a Link Survey invitation modal.</p>
                <p>Ensures the same invite does not reappear if it has already been shown.</p>
              </td>
              <td>365 days duration.</td>
              <td>For more information, please read the Hotjar Cookie Policy</td>
            </tr>
            <tr>
              <td>_hjDonePolls</td>
              <td>
                <p>Set when a user completes an on-site Survey.</p>
                <p>Ensures the same Survey does not reappear if it has already been filled in.</p>
              </td>
              <td>365 days duration.</td>
              <td>For more information, please read the Hotjar Cookie Policy</td>
            </tr>
            <tr>
              <td>_hjMinimizedPolls</td>
              <td>
                <p>Set when a user minimizes an on-site Survey.</p>
                <p>
                  Ensures that the Survey stays minimized when the user navigates through your site.
                </p>
              </td>
              <td>365 days duration.</td>
              <td>For more information, please read the Hotjar Cookie Policy</td>
            </tr>
            <tr>
              <td>_hjShownFeedbackMessage</td>
              <td>
                <p>Set when a user minimizes or completes a Feedback widget.</p>
                <p>
                  Ensures the Feedback widget will load as minimized if the user navigates to
                  another page where it is set to show.
                </p>
              </td>
              <td>One day duration.</td>
              <td>For more information, please read the Hotjar Cookie Policy</td>
            </tr>
          </tbody>
        </table>
        <p>
          Learn more about who we are, how you can contact us and how we process personal data in
          our <a href="https://www.ek.co/privacy-policy/" target="_blank">Privacy Policy</a>.
        </p>
        <p>This policy was last updated on 10th July 2024.</p>
      </div>
    </div>
    <div v-else class="px-3">
      <div class="card mx-3 px-3">
        <!-- dutch translation -->
        <h1>Cookiebeleid - Ekco Sphere</h1>
        <p>
          Onze website gebruikt cookies om u te onderscheiden van andere gebruikers van onze
          website. Dit helpt ons om u een goede ervaring te bieden wanneer u op onze website surft
          en stelt ons ook in staat om onze site te verbeteren.
        </p>
        <p>
          We kunnen dit Cookiebeleid van tijd tot tijd bijwerken. Bekijk de "Laatst bijgewerkt"
          notitie onderaan deze pagina. Wijzigingen in dit Cookiebeleid zijn van kracht vanaf de
          datum dat ze beschikbaar zijn op onze website.
        </p>
        <h2>1. Wat zijn cookies?</h2>
        <p>
          Cookies zijn kleine stukjes informatie, opgeslagen in eenvoudige tekstbestanden, geplaatst
          op uw computer of internetgeschikte apparaat door een website. Meestal worden cookies
          gebruikt om de navigatie door de website te vergemakkelijken. Cookies kunnen door de
          website worden gelezen bij uw volgende bezoeken. Bovendien kan de informatie die in een
          cookie is opgeslagen betrekking hebben op uw surfgedrag op de webpagina, of een uniek
          identificatienummer zodat de website u kan onthouden bij uw volgende bezoek.
        </p>
        <p>
          We gebruiken first-party en third-party cookies om onze website gebruiksvriendelijker te
          maken en om de functionaliteit ervan te ondersteunen. Cookies voorzien ons ook van
          informatie over hoe onze website wordt gebruikt, zodat we ervoor kunnen zorgen dat deze
          up-to-date, relevant en zo foutloos mogelijk is.
        </p>
        <p>
          Door onze website te gebruiken, stemt u in met ons gebruik van strikt noodzakelijke
          cookies en alle andere cookies waarmee u akkoord gaat in onze cookieverklaring. Waar het
          gebruik van deze website resulteert in het verzamelen van Persoonlijke Informatie via
          cookies, is het Privacybeleid van Ekco van toepassing naast dit Beleid.
        </p>
        <h2>2. Gebruikersrechten</h2>
        <p>
          Strikt noodzakelijke cookies zijn altijd ingeschakeld, maar u kunt de andere soorten
          cookies op onze website accepteren of weigeren. Als u ervoor kiest om niet alle cookies te
          accepteren, heeft dit gevolgen voor uw ervaring van onze website en kunt u mogelijk geen
          toegang krijgen tot bepaalde diensten of functies op de website. U kunt op elk moment de
          cookieverklaring op onze website wijzigen of intrekken (zie hieronder).
        </p>
        <h2>3. Soorten cookies</h2>
        <p>We gebruiken de volgende categorieën cookies:</p>
        <ol type="a">
          <li>
            <b>Cookies voor basisoperaties:</b> deze cookies helpen onze website bruikbaar te maken
            door basisfuncties zoals paginanavigatie en toegang tot beveiligde delen van de website
            mogelijk te maken. De website kan niet goed functioneren zonder deze cookies.
          </li>
          <li>
            <b>Cookies voor inhoudspersonalisatie:</b> stelt Ekco in staat om informatie te
            onthouden die de manier waarop onze websites zich gedragen of eruit zien, verandert,
            zoals uw voorkeurstaal of de regio waarin u zich bevindt.
          </li>
          <li>
            <b>Cookies voor site-optimalisatie:</b> helpen Ekco om te begrijpen hoe bezoekers met
            onze websites omgaan door informatie anoniem te verzamelen en te rapporteren.
          </li>
          <li>
            <b>Cookies voor advertentiepersonalisatie:</b> worden gebruikt om bezoekers op onze
            websites te volgen. Het doel is om advertenties weer te geven die relevant en boeiend
            zijn voor de individuele gebruiker en daardoor waardevoller zijn voor uitgevers en
            externe adverteerders.
          </li>
        </ol>
        <p>
          U kunt meer informatie vinden over de individuele cookies die we gebruiken in de tabel in
          onze cookieverklaring.
        </p>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Cookienaam</th>
              <th>Doel</th>
              <th>Wordt bewaard voor</th>
              <th>Meer informatie</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Google Tag Manager</td>
              <td>
                Google Tag Manager (ook bekend als GTM) is een "online tool" waarmee we
                verschillende marketing- en analysetags op onze website kunnen implementeren en
                beheren.
              </td>
              <td>2 jaar</td>
              <td>
                Voor meer informatie, lees Google's overzicht van gegevensprivacy en -bescherming.
              </td>
            </tr>
            <tr>
              <td>Google Analytics</td>
              <td>
                Deze cookies worden gebruikt om informatie te verzamelen over hoe bezoekers onze
                website gebruiken. We gebruiken de informatie om rapporten samen te stellen en om de
                website te helpen verbeteren. De cookies verzamelen informatie op een manier die
                niemand direct identificeert, inclusief het aantal bezoekers aan de website en blog,
                waar bezoekers vandaan kwamen en welke pagina's ze bezochten.
              </td>
              <td>2 jaar</td>
              <td>
                Voor meer informatie, lees Google's overzicht van gegevensprivacy en -bescherming.
              </td>
            </tr>
            <tr>
              <td>Hubspot</td>
              <td>
                We gebruiken HubSpot om uw gegevens op te slaan voor het uitvoeren van
                marketingtaken.
              </td>
              <td>13 maanden</td>
              <td>Voor meer informatie, lees Hubspot's Privacybeleid.</td>
            </tr>
            <tr>
              <td>Mailchimp</td>
              <td>
                We gebruiken MailChimp om onze nieuwsbrief en andere e-maillijsten te beheren. De
                berichten die we via MailChimp versturen, gebruiken geen tracking van derden,
                behalve in MailChimp's eigen analyses, die kunnen aangeven hoeveel abonnees een
                bericht openen of op de links in het bericht klikken.
              </td>
              <td>Onbepaald - totdat de ontvanger van de e-mail de e-mail verwijdert.</td>
              <td>Voor meer informatie, lees MailChimp's privacybeleid.</td>
            </tr>
            <tr>
              <td>Facebook Pixel</td>
              <td>
                Deze cookies worden geplaatst door Facebook. Ze stellen ons in staat om
                advertentiecampagnes op Facebook te meten, te optimaliseren en doelgroepen te
                creëren. In het bijzonder stellen ze ons in staat om te zien hoe onze gebruikers
                zich tussen apparaten verplaatsen wanneer ze onze website en Facebook bezoeken. Dit
                helpt ons ervoor te zorgen dat onze Facebook-advertenties worden gezien door onze
                gebruikers die waarschijnlijk geïnteresseerd zijn in dergelijke advertenties, door
                te analyseren welke inhoud een gebruiker op onze website heeft bekeken en met welke
                inhoud een gebruiker heeft interactie gehad.
              </td>
              <td>
                <p>3 maanden als de optie 'houd me ingelogd' is uitgeschakeld.</p>
                <p>2 jaar als de optie 'houd me ingelogd' is ingeschakeld.</p>
              </td>
              <td>Voor meer informatie, lees Facebook's Privacybeleid</td>
            </tr>
            <tr>
              <td>LinkedIn Insight Tag</td>
              <td>
                Deze cookies worden geplaatst door LinkedIn. Ze stellen ons in staat om
                advertentiecampagnes op LinkedIn te meten, te optimaliseren en doelgroepen te
                creëren. In het bijzonder stellen ze ons in staat om te zien hoe onze gebruikers
                zich tussen apparaten verplaatsen wanneer ze onze website en LinkedIn bezoeken. Dit
                helpt ons ervoor te zorgen dat onze LinkedIn-advertenties worden gezien door onze
                gebruikers die waarschijnlijk geïnteresseerd zijn in dergelijke advertenties, door
                te analyseren welke inhoud een gebruiker op onze website heeft bekeken en met welke
                inhoud een gebruiker heeft interactie gehad.
              </td>
              <td>3 maanden</td>
              <td>Voor meer informatie, lees LinkedIn's Privacybeleid</td>
            </tr>
            <tr>
              <td>Embed/v3/counters.gif</td>
              <td>
                Deze cookie wordt ingesteld door HubSpot. Het verzamelt informatie over
                gebruikersvoorkeuren en/of interactie met webcampagne-inhoud. Dit wordt gebruikt op
                het CRM-campagneplatform dat door website-eigenaren wordt gebruikt om evenementen of
                producten te promoten.
              </td>
              <td>Sessie</td>
              <td>
                Voor meer informatie, zie:
                <a
                  href="https://knowledge.hubspot.com/reports/what-cookies-does-hubspot-set-in-a-visitor-s-browser"
                  target="_blank"
                  >https://knowledge.hubspot.com/reports/what-cookies-does-hubspot-set-in-a-visitor-s-browser</a
                >
              </td>
            </tr>
            <tr>
              <td>hubspotutk</td>
              <td>
                Deze cookie houdt de identiteit van een bezoeker bij. Het wordt doorgegeven aan het
                marketingplatform HubSpot bij het indienen van formulieren en wordt gebruikt voor
                het de-dupliceren van contacten.
              </td>
              <td>1 jaar</td>
              <td>
                Voor meer informatie, zie:
                <a
                  href="https://knowledge.hubspot.com/reports/what-cookies-does-hubspot-set-in-a-visitor-s-browser"
                  target="_blank"
                  >https://knowledge.hubspot.com/reports/what-cookies-does-hubspot-set-in-a-visitor-s-browser</a
                >
              </td>
            </tr>
            <tr>
              <td>__hstc</td>
              <td>
                Dit is de belangrijkste cookie voor het volgen van bezoekers. Het bevat het domein,
                utk, de eerste tijdstempel (eerste bezoek), de laatste tijdstempel (laatste bezoek),
                de huidige tijdstempel (dit bezoek) en het sessienummer (verhoogd voor elke volgende
                sessie).
              </td>
              <td>13 maanden</td>
              <td>Voor meer informatie, lees HubSpot's Privacybeleid</td>
            </tr>
            <tr>
              <td>__hssc</td>
              <td>
                Deze cookie houdt de sessies bij. Dit wordt gebruikt om te bepalen of HubSpot het
                sessienummer en de tijdstempels in de __hstc-cookie moet verhogen. Het bevat het
                domein, viewCount (verhoogt elke pageView in een sessie) en de tijdstempel voor het
                starten van de sessie.
              </td>
              <td>30 minuten</td>
              <td>Voor meer informatie, lees HubSpot's Privacybeleid</td>
            </tr>
            <tr>
              <td>__hssrc</td>
              <td>
                Wanneer HubSpot de sessiecookie wijzigt, wordt deze cookie ook ingesteld om te
                bepalen of de bezoeker zijn browser opnieuw heeft gestart. Als deze cookie niet
                bestaat wanneer HubSpot cookies beheert, wordt dit als een nieuwe sessie beschouwd.
              </td>
              <td>Tot het einde van de sessie</td>
              <td>Voor meer informatie, lees HubSpot's Privacybeleid</td>
            </tr>
            <tr>
              <td>messagesUtk</td>
              <td>
                Deze cookie wordt gebruikt om bezoekers te herkennen die met ons chatten via het
                berichtentool. Als we chatten met een bezoeker die later terugkeert naar de website
                in dezelfde cookie-browser, zal het berichtentool zijn of haar gespreksgeschiedenis
                laden.
              </td>
              <td>13 maanden</td>
              <td>Voor meer informatie, lees HubSpot's Privacybeleid</td>
            </tr>
            <tr>
              <td>__hs_opt_out</td>
              <td>
                Dit is een essentiële/noodzakelijke cookie waarvoor geen toestemming vereist is.
                Deze cookie wordt gebruikt om te onthouden dat de bezoeker niet opnieuw gevraagd zal
                worden om cookies te accepteren.
              </td>
              <td>13 maanden</td>
              <td>Voor meer informatie, lees HubSpot's Privacybeleid</td>
            </tr>
            <tr>
              <td>__hs_do_not_track</td>
              <td>
                Dit is een essentiële/noodzakelijke cookie waarvoor geen toestemming vereist is.
                Deze cookie kan worden ingesteld om te voorkomen dat de trackingcode informatie naar
                HubSpot stuurt. Het staat nog steeds toe dat geanonimiseerde informatie naar HubSpot
                wordt verzonden.
              </td>
              <td>13 maanden</td>
              <td>Voor meer informatie, lees HubSpot's Privacybeleid</td>
            </tr>
            <tr>
              <td>hs_ab_test</td>
              <td>
                Dit is een essentiële/noodzakelijke cookie waarvoor geen toestemming vereist is.
                Deze cookie wordt gebruikt om bezoekers consistent dezelfde versie van de
                landingspagina te tonen die ze eerder hebben gezien.
              </td>
              <td>Tot het einde van de sessie</td>
              <td>Voor meer informatie, lees HubSpot's Privacybeleid</td>
            </tr>
            <tr>
              <td>hs-messages-is-open</td>
              <td>
                Dit is een essentiële/noodzakelijke cookie waarvoor geen toestemming vereist is.
                Deze cookie wordt gebruikt om te bepalen en op te slaan of de chatwidget open is
                voor toekomstige bezoeken. Het reset de widget om deze na 30 minuten inactiviteit te
                sluiten.
              </td>
              <td>30 minuten</td>
              <td>Voor meer informatie, lees HubSpot's Privacybeleid</td>
            </tr>
            <tr>
              <td>hs-messages-disguise-welcome-message</td>
              <td>
                Dit is een essentiële/noodzakelijke cookie waarvoor geen toestemming vereist is.
                Deze cookie wordt gebruikt om te voorkomen dat het welkomstbericht één dag na
                verwijdering verschijnt.
              </td>
              <td>1 dag</td>
              <td>Voor meer informatie, lees HubSpot's Privacybeleid</td>
            </tr>
            <tr>
              <td>__hsmem</td>
              <td>
                Dit is een essentiële/noodzakelijke cookie waarvoor geen toestemming vereist is.
                Deze cookie wordt ingesteld wanneer bezoekers inloggen op een door HubSpot gehoste
                site.
              </td>
              <td>1 jaar</td>
              <td>Voor meer informatie, lees HubSpot's Privacybeleid</td>
            </tr>
            <tr>
              <td>TSxxxxxx</td>
              <td>
                <p>
                  TTGT stelt een interne beveiligingscookie in binnen het inbound-convertersysteem
                  om elementen toe te passen in het beveiligingsbeleid van de load balancer. Deze
                  cookie wordt de mastercookie genoemd. De mastercookie wordt in HTTP-antwoorden op
                  clientverzoeken ingevoegd. De mastercookie dient de volgende functies:
                </p>
                <ul>
                  <li>
                    gebruikt voor het valideren van domeincookies en het kwalificeren van
                    subdomeincookies
                  </li>
                  <li>het herkennen van sessievoortgang</li>
                  <li>
                    het valideren van de integriteit van de frame- of functiecookie indien gebruikt
                  </li>
                </ul>
              </td>
              <td>Tot het einde van de sessie</td>
              <td>Voor meer informatie, lees TechTarget's Cookiebeleid</td>
            </tr>
            <tr>
              <td>SessionUser_{site_id}</td>
              <td>
                <p>Wordt ingesteld wanneer een gebruiker voor het eerst op een pagina landt.</p>
                <p>
                  Behoudt de Hotjar User ID die uniek is voor die site. Hotjar volgt gebruikers niet
                  over verschillende sites.
                </p>
                <p>
                  Zorgt ervoor dat gegevens van volgende bezoeken aan dezelfde site worden
                  toegeschreven aan dezelfde gebruikers-ID.
                </p>
              </td>
              <td>365 dagen</td>
              <td>Voor meer informatie, lees de Hotjar Cookiebeleid</td>
            </tr>
            <tr>
              <td>_hjHasCachedUserAttributes</td>
              <td>
                Hiermee kunnen we weten of de gegevens die zijn ingesteld in het Local Storage-item
                _hjUserAttributes up-to-date zijn of niet.
              </td>
              <td>Duur van de sessie</td>
              <td>Voor meer informatie, lees de Hotjar Cookiebeleid</td>
            </tr>
            <tr>
              <td>_hjUserAttributesHash</td>
              <td>
                Hiermee kunnen we weten wanneer een Gebruikersattribuut is gewijzigd en moet worden
                bijgewerkt.
              </td>
              <td>2 minuten, verlengd elke 30 seconden.</td>
              <td>Voor meer informatie, lees de Hotjar Cookiebeleid</td>
            </tr>
            <tr>
              <td>_hjUserAttributes</td>
              <td>Opslag van Gebruikersattributen verzonden via de Hotjar Identify API.</td>
              <td>Geen expliciete vervaldatum</td>
              <td>Voor meer informatie, lees de Hotjar Cookiebeleid</td>
            </tr>
            <tr>
              <td>hjViewportId</td>
              <td>Opslag van gebruikersweergavedetails zoals grootte en afmetingen.</td>
              <td>Duur van de sessie</td>
              <td>Voor meer informatie, lees de Hotjar Cookiebeleid</td>
            </tr>
            <tr>
              <td>hjActiveViewportIds</td>
              <td>
                <p>Opslag van gebruikersactieve weergave-ID's.</p>
                <p>
                  Opslag van een expirationTimestamp die wordt gebruikt om actieve weergaven te
                  valideren bij scriptinitialisatie.
                </p>
              </td>
              <td></td>
              <td>Voor meer informatie, lees de Hotjar Cookiebeleid</td>
            </tr>
            <tr>
              <td>_hjSession_{site_id}</td>
              <td>
                <p>Houdt huidige sessiegegevens bij.</p>
                <p>
                  Zorgt ervoor dat volgende verzoeken binnen het sessievenster worden toegeschreven
                  aan dezelfde sessie.
                </p>
              </td>
              <td>30 minuten, verlengd bij gebruikersactiviteit.</td>
              <td>Voor meer informatie, lees de Hotjar Cookiebeleid</td>
            </tr>
            <tr>
              <td>_hjCookieTest</td>
              <td>
                <p>
                  Controleert of de Hotjar Tracking Code cookies kan gebruiken. Als dat mogelijk is,
                  wordt een waarde van 1 ingesteld.
                </p>
                <p>Bijna onmiddellijk verwijderd na aanmaak.</p>
              </td>
              <td>
                Minder dan 100ms, vervaltijd van de cookie ingesteld op de duur van de sessie.
              </td>
              <td>Voor meer informatie, lees de Hotjar Cookiebeleid</td>
            </tr>
            <tr>
              <td>_hjLocalStorageTest</td>
              <td>
                <p>Controleert of de Hotjar Tracking Code Local Storage kan gebruiken.</p>
                <p>Als dat mogelijk is, wordt een waarde van 1 ingesteld.</p>
                <p>
                  Gegevens opgeslagen in _hjLocalStorageTest hebben geen vervaldatum, maar worden
                  bijna onmiddellijk verwijderd na aanmaak.
                </p>
              </td>
              <td>Minder dan 100ms duur.</td>
              <td>Voor meer informatie, lees de Hotjar Cookiebeleid</td>
            </tr>
            <tr>
              <td>_hjSessionStorageTest</td>
              <td>
                <p>
                  Controleert of de Hotjar Tracking Code Session Storage kan gebruiken. Als dat
                  mogelijk is, wordt een waarde van 1 ingesteld.
                </p>
                <p>
                  Gegevens opgeslagen in _hjSessionStorageTest hebben geen vervaldatum, maar worden
                  bijna onmiddellijk verwijderd na aanmaak.
                </p>
              </td>
              <td>Minder dan 100ms duur.</td>
              <td>Voor meer informatie, lees de Hotjar Cookiebeleid</td>
            </tr>
            <tr>
              <td>_hjTLDTest</td>
              <td>
                <p>
                  We proberen de cookie _hjTLDTest op te slaan voor verschillende URL-substring
                  alternatieven totdat dit mislukt.
                </p>
                <p>
                  Hiermee kunnen we proberen het meest generieke cookiepad te bepalen om te
                  gebruiken in plaats van de hostnaam van de pagina.
                </p>
                <p>
                  Dit betekent dat cookies kunnen worden gedeeld over subdomeinen (waar van
                  toepassing).
                </p>
                <p>Na deze controle wordt de cookie verwijderd.</p>
              </td>
              <td>Duur van de sessie.</td>
              <td>Voor meer informatie, lees de Hotjar Cookiebeleid</td>
            </tr>
            <tr>
              <td>hjClosedSurveyInvites</td>
              <td>
                <p>
                  Ingesteld wanneer een gebruiker interactie heeft met een Link
                  Survey-uitnodigingsvenster.
                </p>
                <p>
                  Zorgt ervoor dat dezelfde uitnodiging niet opnieuw verschijnt als deze al is
                  getoond.
                </p>
              </td>
              <td>365 dagen duur.</td>
              <td>Voor meer informatie, lees de Hotjar Cookiebeleid</td>
            </tr>
            <tr>
              <td>_hjDonePolls</td>
              <td>
                <p>Ingesteld wanneer een gebruiker een on-site enquête voltooit.</p>
                <p>
                  Zorgt ervoor dat dezelfde enquête niet opnieuw verschijnt als deze al is ingevuld.
                </p>
              </td>
              <td>365 dagen duur.</td>
              <td>Voor meer informatie, lees de Hotjar Cookiebeleid</td>
            </tr>
            <tr>
              <td>_hjMinimizedPolls</td>
              <td>
                <p>Ingesteld wanneer een gebruiker een on-site enquête minimaliseert.</p>
                <p>
                  Zorgt ervoor dat de enquête geminimaliseerd blijft wanneer de gebruiker door uw
                  site navigeert.
                </p>
              </td>
              <td>365 dagen duur.</td>
              <td>Voor meer informatie, lees de Hotjar Cookiebeleid</td>
            </tr>
            <tr>
              <td>_hjShownFeedbackMessage</td>
              <td>
                <p>
                  Ingesteld wanneer een gebruiker een Feedback widget minimaliseert of voltooit.
                </p>
                <p>
                  Zorgt ervoor dat de Feedback widget geminimaliseerd wordt geladen als de gebruiker
                  naar een andere pagina navigeert waar deze is ingesteld om te tonen.
                </p>
              </td>
              <td>Eén dag duur.</td>
              <td>Voor meer informatie, lees de Hotjar Cookiebeleid</td>
            </tr>
          </tbody>
        </table>
        <p>
          Lees meer over wie we zijn, hoe u contact met ons kunt opnemen en hoe wij persoonsgegevens
          verwerken in ons
          <a href="https://www.ek.co/privacy-policy/" target="_blank">Privacybeleid</a>.
        </p>
        <p>Dit beleid is voor het laatst bijgewerkt op 10 juli 2024.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CookiePolicyPage',
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapState(useUserSettingsStore, ['userSettings']),
    language() {
      return this.userSettings.language;
    },
  },
  methods: {},
};
</script>

<style scoped>
.highlight-row {
  background-color: var(--bs-tertiary-bg) !important;
}
</style>
