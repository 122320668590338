/** @see {@link apollo_platform/apps/invitations/api/serializers.py} > CustomerRelationInvitationSerializer */
export interface InvitationCustomer {
  id: number;
  name: string;
  debtor_number: string;
  role: string | null;
}

export interface Invitation {
  id: number;
  email: string;
  created: string;
  sent: string | null;
  accepted: boolean;
  accepted_at: string | null;
  inviter: string;
  customers: InvitationCustomer[];
  roles: UserRelationRole[];
  groups: UserGroup[];
  url: string;
  key_expired: boolean;
}

export type InvitationCreate = {
  email: string;
  customers: Customer[];
  groups: UserGroup[];
  role: UserRelationRole;
  sending_domain: string;
};

export const useInvitationsStore = defineStore('invitations', {
  state: () => ({
    invitations: null as Invitation[] | null,
  }),
  getters: {
    getInvitations: (state): Invitation[] => state.invitations || [],
    getUserInvitations() {
      return (email: string) => this.getInvitations.filter((i) => i?.email === email);
    },
  },
  actions: {
    setInvitations(invitations: Invitation[]) {
      useLocalStorage().setItem('invitations', invitations);
      this.invitations = invitations;
    },
    async createInvitation(invitation: InvitationCreate) {
      try {
        const {data} = await apiPostInvitation(invitation);
        return data;
      } catch (error) {
        throw apiErrorMessage(error);
      }
    },
    async readInvitations() {
      try {
        const {data} = await apiGetInvitations(); // This endpoint uses cursor pagination
        // Get the next cursor data
        const aggregatedInvitationResults: Invitation[] =
          data.next !== null ? await apiGetPaginatedResults(apiGetInvitations, data.next) : [];
        // Return both the first cursor & rest of the aggregated cursors.
        const results = [...data.results, ...aggregatedInvitationResults];
        this.setInvitations(results);
        return results;
      } catch (error) {
        throw apiErrorMessage(error);
      }
    },
    async updateInvitation<T extends InvitationAction>(
      id: number,
      action: T,
      data: DataOfAction<T>,
    ): Promise<Invitation | undefined> {
      try {
        const response = await apiPutInvitation(id, action, data);
        return response.data;
      } catch (error) {
        throw apiErrorMessage(error);
      }
    },
  },
});
