/**
 * @see {@link https://kentcdodds.com/blog/get-a-catch-block-error-message-with-typescript}
 * @see {@link https://www.youtube.com/watch?v=YpT0rCWdCaE&list=UUYCJ5V6CYFhUr7AhlKaYL4A}
 */

import {AxiosError} from 'axios';

export interface Detail {
  loc: string[];
  msg: string;
  type: string;
}

export const apiErrorMessage = (
  error: unknown,
  full: boolean = false,
): Detail[] | string | object => {
  /* eslint-disable no-console */
  if (import.meta.env.DEV) console.log(error);
  // Requests sent by the Sphere use Axios
  if (error instanceof AxiosError) {
    if (error.response !== undefined) {
      if (full) return error.response;
      if (error.response.data.detail) {
        return error.response.data.detail;
      }
      return error.response.statusText;
    }
    return error.message;
  }

  // In the off chance, error is not an AxiosError
  if (error instanceof Error) return error.message;
  return String(error);
};
