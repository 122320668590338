<template>
  <div class="card">
    <div class="card-header m-0 px-2 py-3" style="padding: 0.75">
      <ErrorComponent v-if="errorMessage" class="m-0 pb-2" :errors="[errorMessage]" />
      <h6 class="m-0" data-testid="team-page.invitations.section.heading">Invitations</h6>
    </div>
    <BootstrapFormControl
      v-model="searchQuery"
      class="w-25 p-1"
      name="invitation-filter"
      placeholder="Type email to search..."
      only-input
      size="sm"
    />
    <table
      class="table table-striped table-hover table-borderless rounded px-5"
      data-testid="team-page.invitations.table"
    >
      <thead data-testid="team-page.invitations.table.thead">
        <tr>
          <th v-for="(name, index) in colsNames" :key="index" class="fw-normal">
            <template v-if="index === 0">
              <input
                v-if="isInvitationManager"
                id="inviteCheckbox"
                v-model="selectAllCheckedIdsModel"
                class="form-check-input"
                type="checkbox"
                :value="false"
                data-testid="team-page.invitations.thead.select-all.checkbox"
              />
            </template>
            <template v-else-if="name === 'Actions'" />
            <template v-else>{{ name }}</template>
          </th>
        </tr>
      </thead>
      <tbody class="table-group-divider" data-testid="team-page.invitations.tbody">
        <tr
          v-if="
            loading === false &&
            (currentPageInvitations === undefined || currentPageInvitations.length === 0)
          "
          data-testid="team-page.invitations.tbody.invitation-item.none.placeholder"
        >
          <td colspan="999">
            <span class="d-flex justify-content-center">No invitations found</span>
          </td>
        </tr>
        <template
          v-else-if="
            loading === true &&
            (currentPageInvitations === undefined || currentPageInvitations.length === 0)
          "
        >
          <tr
            v-for="item in placeholderNum(5, 9)"
            :key="item"
            data-testid="team-page.invitations.tbody.invitation-item.loading.placeholder"
          >
            <td v-for="col in colsNames" :key="col" class="placeholder-glow">
              <span class="placeholder w-100"></span>
            </td>
          </tr>
        </template>
        <template v-else>
          <tr
            v-for="invitation in currentPageInvitations"
            :key="invitation.id"
            data-testid="team-page.invitations.tbody.invitations.item"
            :data-testrowid="invitation.id"
          >
            <td class="align-middle">
              <input
                v-if="isInvitationManager"
                :id="`inviteCheckbox-${invitation.id}`"
                v-model="checkedIdsModel"
                class="form-check-input"
                :value="invitation.id"
                type="checkbox"
                true-value="yes"
                false-value="no"
                data-testid="team-page.invitations.tbody.invitations.item.checkbox"
              />
            </td>
            <td
              data-hj-suppress
              data-testid="team-page.invitations.tbody.invitations.item.email.inivitee"
            >
              {{ invitation.email }}
            </td>
            <td data-testid="team-page.invitations.tbody.invitations.item.date-sent">
              {{ getDate(invitation.sent) }}
            </td>
            <td data-testid="team-page.invitations.tbody.invitations.item.date-expire">
              {{ getExpiration(invitation.sent) }}
            </td>
            <td
              data-hj-suppress
              data-testid="team-page.invitations.tbody.invitations.item.email.inviter"
            >
              {{ invitation.inviter }}
            </td>
            <td data-testid="team-page.invitations.tbody.invitations.item.role">
              {{ getRole(invitation) }}
            </td>
            <td
              data-hj-suppress
              data-testid="team-page.invitations.tbody.invitations.item.customers"
            >
              <div class="hstack gap-1 flex-wrap">
                <span
                  v-for="(customer, index) in invitation.customers"
                  :key="index"
                  data-hj-suppress
                >
                  <BootstrapButton
                    v-if="checkedIdsModel.includes(invitation.id)"
                    variant="danger"
                    size="sm"
                    type="submit"
                    :loading="
                      invitation.customers.length > 1 && activeCustomerDeleteId === customer.id
                    "
                    outlined
                    :disabled="invitation.customers.length === 1 || deleteCustomerLoading"
                    :name="`group-delete-${index}`"
                    @keypress.stop
                    @click="removeCustomer(customer.id)"
                  >
                    {{ customer.name }}
                    <Icon
                      v-if="invitation.customers.length > 1 && !deleteCustomerLoading"
                      icon="material-symbols:close"
                      class="flex-shrink-0 ms-1 p-0"
                    />
                  </BootstrapButton>
                  <template v-else>
                    {{
                      index + 1 !== invitation.customers.length
                        ? `${customer.name},`
                        : customer.name
                    }}
                  </template>
                </span>
                <IconButton
                  v-if="checkedIdsModel.includes(invitation.id)"
                  icon-name="material-symbols:edit-square-outline"
                  class="text-primary flex-shrink-0"
                  data-testid="team-page.invitations.tbody.invitations.item.customers.button.edit"
                  @keypress.stop
                  @click="$emit('openCustomersForm', selectedInvitations)"
                />
              </div>
            </td>
            <td data-hj-suppress data-testid="team-page.invitations.tbody.invitations.item.groups">
              <div class="hstack gap-1 flex-wrap me-2">
                <span v-for="(group, index) in invitation.groups" :key="index" data-hj-suppress>
                  <BootstrapButton
                    v-if="checkedIdsModel.includes(invitation.id)"
                    variant="danger"
                    size="sm"
                    type="submit"
                    outlined
                    :loading="invitation.groups.length > 1 && activeGroupDeleteId === group.id"
                    :disabled="invitation.groups.length === 1 || deleteGroupLoading"
                    :name="`group-delete-${index}`"
                    @keypress.stop
                    @click="removeGroup(group.id)"
                  >
                    {{ group.name }}
                    <Icon
                      v-if="invitation.groups.length > 1 && !deleteGroupLoading"
                      icon="material-symbols:close"
                      class="flex-shrink-0 ms-1 p-0"
                    />
                  </BootstrapButton>
                  <template v-else>
                    {{ index + 1 !== invitation.groups.length ? `${group.name},` : group.name }}
                  </template>
                </span>
                <IconButton
                  v-if="checkedIdsModel.includes(invitation.id)"
                  icon-name="material-symbols:edit-square-outline"
                  class="text-primary flex-shrink-0"
                  data-testid="team-page.invitations.tbody.invitations.item.groups.button.edit"
                  @keypress.stop
                  @click="$emit('openGroupsForm', selectedInvitations)"
                />
              </div>
            </td>
            <td class="align-middle">
              <div class="d-flex">
                <IconButton
                  v-if="
                    invitation.key_expired === false && invitation.email === userStore.user?.email
                  "
                  icon-name="material-symbols:check-box-outline"
                  :loading="acceptLoading && rowActionId === invitation.id"
                  style="width: max-content; height: max-content"
                  class="text-success py-2 me-1"
                  data-testid="team-page.invitations.tbody.invitations.item.button.accept"
                  @click.stop="acceptInvitation(invitation)"
                />
                <IconButton
                  v-if="isInvitationManager || invitation.email === userStore.user?.email"
                  icon-name="material-symbols:delete-outline"
                  :loading="deleteLoading && rowActionId === invitation.id"
                  style="width: max-content; height: max-content"
                  class="text-danger py-2"
                  data-testid="team-page.invitations.tbody.invitations.item.button.remove"
                  @keypress.stop
                  @click.stop="removeInvitation(invitation)"
                />
              </div>
            </td>
          </tr>
        </template>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="999">
            <PaginationComponent
              :count="count"
              :total="total"
              :limit="PAGE_SIZE"
              @update-cursor="updatePage"
            />
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>
<script lang="ts" setup>
import {Invitation, TeamRole} from '@/stores/apolloPlatform/team/team';
import {displayTimeStamp} from '@/timestamp';

/**
 * TODO: Needs a proper re-write, not just composition API
 * This file is a merger of InvitationList & InvitationEntry
 */
defineProps<{
  customers: Customer[];
  isInvitationManager: boolean;
  loading?: boolean;
}>();

defineEmits<{
  (event: 'close'): void;
  (event: 'submit', customers: Customer[]): void;
  (event: 'search', value: string): void;
  (event: 'openCustomersForm', invitation: Invitation[]): void;
  (event: 'openGroupsForm', invitation: Invitation[]): void;
}>();

const PAGE_SIZE = 20;

const {axiosApiInstance} = useApiInstance();
const {placeholderNum} = usePlaceholderNum();

// Stores
const teamStore = useTeamStore();
const userStore = useUserStore();
const userSettingsStore = useUserSettingsStore();

// Store methods
const {fetchInvitations} = teamStore;

// Store refs
const {invitations, roles} = storeToRefs(teamStore);

// Computed
const language = computed(() => userSettingsStore.userSettings.language);

/**
 * Readonly object for mapping between <th> display names and row data object keys
 */
const colsMap = readonly<{[key: string]: string}>({
  Checkboxes: 'checkboxes',
  Email: 'email',
  Sent: 'sent',
  Expiration: 'expiration',
  Inviter: 'Inviter',
  Role: 'role',
  Customers: 'customers',
  Groups: 'groups',
  Actions: 'actions',
});
const colsNames = ref<string[]>(Object.keys(colsMap));
const errorMessage = ref();
const deleteLoading = ref(false);
const acceptLoading = ref(false);
const rowActionId = ref();

const deleteCustomerLoading = ref(false);
const deleteGroupLoading = ref(false);

const activeCustomerDeleteId = ref();
const activeGroupDeleteId = ref();

const checkedIdsModel = ref<number[]>([]);
const selectAllCheckedIdsModel = computed({
  get: () => {
    return (invitations.value?.length ?? 0) > 0
      ? checkedIdsModel.value.length === invitations.value?.length
      : false;
  },
  set: (value: boolean) => {
    let allSelected: number[] = [];
    if (value === true && invitations.value) {
      allSelected = invitations.value.map((i) => i.id);
    }
    checkedIdsModel.value = allSelected;
  },
});

const sortedInvitations: globalThis.ComputedRef<Invitation[] | undefined> = computed(() => {
  const array = invitations.value;
  return array?.sort((a: any, b: any) => a.email.localeCompare(b.email)) || [];
});

// Search filter string
const searchQuery = ref<string>('');

// Sorted invitations after the search filter has been applied
const filteredSortedInvitations = computed(() => {
  if (searchQuery.value === '') {
    return sortedInvitations.value;
  }

  return sortedInvitations.value?.filter((invitation) =>
    invitation.email.toLowerCase().includes(searchQuery.value.toLowerCase()),
  );
});

// The total number of invitations
const total = computed(() => filteredSortedInvitations.value?.length || 0);
// The total number of pages
const count = computed(() => Math.floor((total.value + PAGE_SIZE - 1) / PAGE_SIZE));
// Current page number
const page = ref(1);

// Retrieve all invitations to display on the current page
const currentPageInvitations = computed(() => {
  const startIndex = (page.value - 1) * PAGE_SIZE;
  const endIndex = startIndex + PAGE_SIZE;
  return filteredSortedInvitations.value?.slice(startIndex, endIndex) || [];
});

const updatePage = (newPageNum: number) => {
  page.value = newPageNum;
};

const selectedInvitations = computed((): Invitation[] => {
  const invs = invitations.value;
  if (!invs) return [];

  const selected = [];
  for (const checked of checkedIdsModel.value) {
    const found = invs.find((i) => i.id === checked);
    if (found) selected.push(found);
  }
  return selected;
});

const getExpiration = (time: string | null): string => {
  if (!time) return '-';
  const date = new Date(time);
  const datePlusSevenDays = new Date(date.setDate(date.getDate() + 7));
  return displayTimeStamp(datePlusSevenDays, language.value);
};
const getDate = (time: string | null): string => {
  if (!time) return '-';
  const date = new Date(time);
  return displayTimeStamp(date, language.value);
};

const removeInvitation = async (invitation: Invitation) => {
  rowActionId.value = invitation.id;
  deleteLoading.value = true;
  try {
    await axiosApiInstance({
      method: 'put',
      url: `invitations/${invitation.id}/decline`,
    });
    await fetchInvitations();
  } catch (error) {
    console.log(error);
  } finally {
    deleteLoading.value = false;
  }
};

const acceptInvitation = async (invitation: Invitation) => {
  rowActionId.value = invitation.id;
  acceptLoading.value = true;
  try {
    await axiosApiInstance({
      method: 'put',
      url: `invitations/${invitation.id}/accept`,
    });
    await fetchInvitations();
    await userStore.readUser();
  } catch (error) {
    console.log(error);
  } finally {
    acceptLoading.value = false;
  }
};

const removeCustomer = async (customerId: number) => {
  activeCustomerDeleteId.value = customerId;
  deleteCustomerLoading.value = true;
  try {
    await Promise.all(
      checkedIdsModel.value.map(async (inviteId) => {
        const invitation = invitations.value?.find((i) => i.id === inviteId);
        // Check if the group exists for the invitation
        if (
          invitation !== undefined &&
          invitation.customers.find((c) => c.id === customerId) &&
          invitation.customers.length > 1
        ) {
          await axiosApiInstance({
            method: 'put',
            url: `invitations/${inviteId}/remove_customer/`,
            data: {customer_id: customerId},
          });
        }
      }),
    );
    await teamStore.fetchInvitations();
  } catch (error) {
    console.log(error);
  } finally {
    deleteCustomerLoading.value = false;
    activeCustomerDeleteId.value = undefined;
  }
};

const removeGroup = async (groupId: number) => {
  activeGroupDeleteId.value = groupId;
  deleteGroupLoading.value = true;
  try {
    await Promise.all(
      checkedIdsModel.value.map(async (inviteId) => {
        const invitation = invitations.value?.find((i) => i.id === inviteId);
        // Check if the group exists for the invitation
        if (
          invitation !== undefined &&
          invitation.groups.find((g) => g.id === groupId) &&
          invitation.groups.length > 1
        ) {
          await axiosApiInstance({
            method: 'put',
            url: `invitations/${inviteId}/remove_group/`,
            data: {group_id: groupId},
          });
        }
      }),
    );
    await teamStore.fetchInvitations();
  } catch (error) {
    console.log(error);
  } finally {
    deleteGroupLoading.value = false;
    activeGroupDeleteId.value = undefined;
  }
};

const getRole = (invitation: Invitation) => {
  let role: TeamRole | undefined;
  let outputRole = 'Employee';
  if (invitation.customers.length > 0) {
    let roleSearch = invitation.customers[0].role;
    if (roleSearch) {
      role = roles.value.find((role) => role.name === roleSearch);
    }
  }
  if (role !== undefined) {
    outputRole = role.value;
  }
  return outputRole;
};
</script>
