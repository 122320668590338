<template>
  <DialogLayout :open="props.open" @close="$emit('close')" @submit="submit">
    <template #header>Add Groups</template>
    <template v-if="allowedDomainOptions.length > 0">
      <div v-for="(group, index) in allowedDomainOptions" :key="index" class="form-check">
        <input
          :id="`groupCheckbox-${group.id}`"
          v-model="checkedIdsModel"
          class="form-check-input"
          :value="group.id"
          type="checkbox"
          true-value="yes"
          false-value="no"
        />
        <label class="form-check-label" :for="`groupCheckbox-${group.id}`">
          {{ group.allowed_domains ? `${group.name} (${group.allowed_domains})` : group.name }}
        </label>
      </div>
    </template>
    <div v-else>
      <div class="d-flex flex-column">
        <span class="mb-2">No groups found.</span>
        <span>Please try again using the search button.</span>
      </div>
    </div>
    <template #buttons>
      <BootstrapButton
        name="submit-button"
        class="me-2"
        type="submit"
        :disabled="loading"
        :loading="loading"
        data-testid="team-page.invitaions.edit-dialog.groups.button.submit"
        @click.stop
      >
        Save
      </BootstrapButton>
      <BootstrapButton
        name="cancel-button"
        variant="secondary"
        data-testid="team-page.invitaions.edit-dialog.groups.button.cancel"
        @click="$emit('close')"
      >
        Cancel
      </BootstrapButton></template
    >
  </DialogLayout>
</template>
<script lang="ts" setup>
import {emailGetDomain} from '@/sphere/email';
import {Invitation} from '@/stores/apolloPlatform/team/team';

const props = defineProps<{
  groups: UserGroup[];
  invitations?: Invitation[];
  open: boolean;
  loading?: boolean;
  searchLoading?: boolean;
}>();

const emit = defineEmits<{
  (event: 'close'): void;
  (event: 'submit', groups: UserGroup[]): void;
  (event: 'search', value: string): void;
}>();

const checkedIdsModel = ref([]);
const options = computed(() => props.groups || []);
// All already selected invites groups
const selectedOptions = computed(() => props.invitations?.flatMap((i) => i.groups) || []);
/**
 * When selecting multiple invites, if a group is not common to a single invite
 * Then the group will be displayed as an option to add to multiple invites.
 * Remove group from selectedOptions if it does not appear in each invitation once
 */
const uniqueSelectedOptions = computed(() =>
  selectedOptions.value.filter((o) => {
    const requiredCount = props.invitations?.length || 0;
    const optionCount = selectedOptions.value.filter((so) => so.id === o.id).length || 0;
    // Does the group appear in both invitations? Then return is as already selected
    if (optionCount === requiredCount) return o;
  }),
);

const removedOptions = computed(() => {
  return options.value.filter((o) => {
    return !uniqueSelectedOptions.value.find((so) => o.id === so.id);
  });
});

const inviteDomains = computed(() => props.invitations?.map((i) => emailGetDomain(i.email)) || []);

const allowedDomainOptions = computed(() =>
  removedOptions.value.filter((o) => {
    if (!o.allowed_domains) {
      return o;
    } else {
      for (const inviteDomain of inviteDomains.value) {
        if (o.allowed_domains.includes(inviteDomain) === true) return o;
      }
    }
  }),
);

const submit = () => {
  if (checkedIdsModel.value.length === 0) emit('close');
  else {
    const groups: any = checkedIdsModel.value.map((id) => props.groups.find((c) => id === c.id));
    emit('submit', groups);
  }
};

const isOpen = computed(() => props.open);
watch(isOpen, (newValue) => {
  if (newValue === false) checkedIdsModel.value = [];
});
</script>
