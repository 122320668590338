import {apolloPlatformAxiosInstance as axiosInstance} from './apolloPlatformAxiosInstance';

/**
 * User settings API
 */

export const apiGetUserSettings = async () => {
  return await axiosInstance.get('user_settings/me/');
};

export const apiPutUserSettings = async (userSettings: UserSettings) => {
  return await axiosInstance.put('user_settings/me/', userSettings);
};
