<template>
  <div ref="tooltipElement" data-bs-toggle="tooltip">
    <slot class="w-full h-full" />
  </div>
</template>
<script setup>
import Tooltip from 'bootstrap/js/dist/tooltip.js';

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  placement: {
    type: String,
    default: 'top',
    validators: (value) => ['top', 'left', 'bottom', 'right'].includes(value),
  },
  container: {
    type: String,
    default: 'body',
  },
});

const tooltipElement = ref(null);
const tooltip = ref(null);

const createTooltip = () => {
  if (tooltip.value !== null) {
    tooltip.value.dispose();
  }
  tooltip.value = new Tooltip(tooltipElement.value, {
    trigger: 'hover',
    container: props.container || false,
    title: props.title,
    placement: props.placement,
  });
};

const removeTooltip = () => {
  if (tooltip.value !== null) {
    tooltip.value.dispose();
    tooltip.value = null;
  }
};

onMounted(createTooltip);
onUnmounted(removeTooltip);
onActivated(createTooltip);
onDeactivated(removeTooltip);

watch(
  () => props.title,
  () => {
    if (props.title !== '') {
      createTooltip();
      tooltip.value.setContent({'.tooltip-inner': props.title});
    } else {
      removeTooltip();
    }
  },
);
</script>
