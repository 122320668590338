interface RequestFactoryFn<Type> {
  (): Promise<Type>;
}

const inProgressRequestCache: Record<string, Promise<any> | undefined> = {};

/**
 * Function that combines new requests with currently in progress requests.
 * @param key Value that uniquely identifies this request.
 * @param requestFactory Function that returns a Promise that will be cached.
 */
export function combineRequest<Type>(
  key: string,
  requestFactory: RequestFactoryFn<Type>,
): Promise<Type> {
  if (inProgressRequestCache[key]) {
    // Request already in progress, return that
    return inProgressRequestCache[key] as Promise<Type>;
  }

  const newPromise = requestFactory();
  inProgressRequestCache[key] = newPromise;

  return newPromise.finally(() => {
    delete inProgressRequestCache[key];
  });
}
