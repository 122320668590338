<template>
  <div class="sidebar-main-title sidebar-list mx-3">
    <div>
      <h6 class="lan-1">
        <Icon icon="material-symbols:dangerous-outline" class="top" />
        <li
          v-for="(error, index) in errors"
          :key="index"
          style="list-style-type: none"
          class="mt-2 px-4"
        >
          {{ error }}
        </li>
      </h6>
      <p class="lan-2 mt-3 px-4">{{ descriptions }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorComponent',
  props: {
    errors: {
      type: Array,
    },
    descriptions: {
      type: String,
    },
  },
};
</script>
