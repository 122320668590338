<template>
  <article class="d-flex">
    <div v-if="props.showPageLimitOptions" class="d-flex">
      <DropdownSelect class="pe-2">
        <template #toggle>
          <BootstrapButton
            name="view-select"
            variant="secondary"
            size="sm"
            class="dropdown-toggle w-100"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <Icon icon="material-symbols:list" class="pe-2" :inline="true" />
            <span class="name">{{ props.limit }}</span>
          </BootstrapButton>
        </template>
        <template #items>
          <button
            v-for="option in pageLimitOptions"
            :key="option"
            class="dropdown-item text-wrap w-100 controls"
            @click="$emit('updatePageLimit', option)"
          >
            {{ option }}
          </button>
        </template>
      </DropdownSelect>
      <sub class="d-flex align-items-center justify-content-center">
        {{ (currentPage - 1) * props.limit + 1 }} -
        {{ Math.min(currentPage * props.limit, props.total) }}
        of {{ props.total }}
      </sub>
    </div>
    <div class="flex-grow-1" />
    <nav v-if="props.count > 1">
      <ul class="pagination pagination-sm">
        <li
          :class="[
            'page-item',
            currentPage === 1 ? '' : 'page-item-hover',
            `page-item-${props.size}`,
          ]"
          :disabled="currentPage === 1"
          @click="currentPage > 1 ? (currentPage = currentPage - 1) : ''"
        >
          <a class="page-link" aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
        <li
          :class="[
            'page-item',
            currentPage === pages[0] ? 'active' : 'page-item-hover',
            `page-item-${props.size}`,
          ]"
          @click="currentPage = pages[0]"
        >
          <a class="page-link" :class="currentPage === pages[0] ? 'active' : ''">{{ pages[0] }}</a>
        </li>
        <li v-if="startEllipses" :class="`page-item page-item-${props.size}`">
          <a class="page-link">...</a>
        </li>
        <li
          v-for="pageItem in filterPages"
          :key="pageItem"
          :class="[
            'page-item',
            currentPage === pageItem ? 'active' : 'page-item-hover',
            `page-item-${props.size}`,
          ]"
          @click="currentPage = pageItem"
        >
          <a class="page-link" :class="currentPage === pageItem ? 'active' : ''">{{ pageItem }}</a>
        </li>
        <li v-if="endEllipses" :class="`page-item page-item-${props.size}`">
          <a class="page-link">...</a>
        </li>
        <li
          :class="[
            'page-item',
            currentPage === pages[props.count - 1] ? 'active' : 'page-item-hover',
            `page-item-${props.size}`,
          ]"
          @click="currentPage = pages[props.count - 1]"
        >
          <a class="page-link" :class="currentPage === pages[props.count - 1] ? 'active' : ''">{{
            pages[props.count - 1]
          }}</a>
        </li>
        <li
          :class="[
            'page-item',
            `page-item-${props.size}`,
            currentPage === props.count ? 'disabled' : 'page-item-hover',
          ]"
          :disabled="currentPage === props.count"
          @click="currentPage < count ? (currentPage = currentPage + 1) : ''"
        >
          <a class="page-link" aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </nav>
  </article>
</template>

<script lang="ts" setup>
const pageLimitOptions = [25, 50, 75, 100];

interface Props {
  count: number;
  total?: number;
  page?: number;
  limit?: number;
  size?: string;
  showPageLimitOptions?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  page: 1,
  total: 0,
  size: 'md',
  limit: 25,
  showPageLimitOptions: false,
});

const emits = defineEmits(['updateCursor', 'updatePageLimit']);

const currentPage = ref(props.page || 1);

const pages = computed(() => {
  const pages = [];
  for (let i = 1; i <= props.count; i++) {
    pages.push(i);
  }

  return pages;
});

const filterPages = computed(() => {
  // page count 5 + 2 for ellipses
  const visiblePageCount = 5;

  if (props.count < 8) {
    return pages.value.slice(1, props.count - 1);
  }
  if (currentPage.value < 5) {
    return pages.value.slice(1, visiblePageCount);
  } else if (currentPage.value > props.count - 2) {
    return pages.value.slice(props.count - visiblePageCount, props.count - 1);
  } else {
    if (currentPage.value >= props.count - 2) {
      return pages.value.slice(currentPage.value - 3, currentPage.value + 1);
    } else {
      return pages.value.slice(currentPage.value - 2, currentPage.value + 1);
    }
  }
});

const startEllipses = computed(() => currentPage.value > 4 && 2 !== filterPages.value[0]);
const endEllipses = computed(
  () =>
    currentPage.value < props.count - 2 &&
    props.count - 1 !== filterPages.value[filterPages.value.length - 1],
);

watch(currentPage, (newValue, oldValue) => {
  if (newValue !== oldValue) emits('updateCursor', currentPage.value);
});

watch(
  () => props.page,
  () => {
    currentPage.value = props.page || 1;
  },
  {immediate: true},
);
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

$sizes: (
  'sm': 1.2rem,
  'md': 1.5rem,
  'lg': 2rem,
);

@each $sizeStr, $value in $sizes {
  .page-item-#{$sizeStr} {
    display: flex;
    justify-content: center;
    align-items: center;
    width: $value;
    height: $value;
    border: 1px solid var(--bs-border-color);
    border-radius: 0.25rem;
    background-color: var(--bs-body-bg);
  }

  .page-item-#{$sizeStr}.active {
    transition: background-color 0.3s ease;
    background-color: var(--bs-primary) !important;
    border-color: var(--bs-border-color);
    color: var(--bs-white);
  }
}

.page-item-hover:hover {
  transition: background-color 0.3s ease;
  background-color: var(--bs-primary-border-subtle);
  cursor: pointer;
}

.page-link {
  user-select: none;
  color: var(--bs-table-color-state);
}
</style>
