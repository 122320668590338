import {createRouter, createWebHistory} from 'vue-router';
import {
  ErrorPage,
  MainPage,
  NewsItemPage,
  NewsPage,
  TeamPage,
  CookiePolicyPage,
  OldCoreUrlRedirect,
  LoadingPage,
  NotFoundPage,
} from '@/pages/index.ts';
import config from '@/config';

const routes = [
  {
    path: '',
    redirect: {
      name: 'home',
    },
  },
  {
    path: '/',
    component: MainPage,
    children: [
      {
        path: 'home',
        name: 'home',
        redirect: '/core/home',
      },
      {
        path: 'team',
        name: 'team',
        component: TeamPage,
      },
      {
        path: 'error',
        name: 'error',
        component: ErrorPage,
        props: true,
      },
      {
        path: 'news',
        name: 'news',
        component: NewsPage,
      },
      {
        path: 'news/:id',
        name: 'newsitem',
        component: NewsItemPage,
      },
      {
        path: 'cookiepolicy',
        name: 'cookiepolicy',
        component: CookiePolicyPage,
        props: true,
      },
      {
        // Old path with numeric identifier
        path: 'core/:id(\\d+)/:core/:corePath*',
        component: OldCoreUrlRedirect,
        props: true,
      },
      {
        path: 'core',
        name: 'core-base',
        children: [
          {
            // The cores store replaces this placeholder route once the portal
            // navigation is loaded.
            name: 'core-loading',
            path: ':any*',
            component: LoadingPage,
          },
        ],
      },
      {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: NotFoundPage,
      },
    ],
  },
];

/// Reusable function for isolated tests
export function buildRouter() {
  return createRouter({
    history: createWebHistory(config.BASE_URL),
    routes,
    linkActiveClass: 'active',
    scrollBehavior() {
      return {left: 0, top: 0};
    },
  });
}

const router = buildRouter();

router.beforeEach((to, from) => {
  if (from.path === '/error') {
    // block route change if coming from error page
    return false;
  } else if (to.path === '/core/product_catalogue') {
    return '/core/product_catalogue/product_overview';
  }
});

export default router;
