<template>
  <section class="container-fluid overflow-x-scroll h-100" data-testid="team-page">
    <div class="row w-100">
      <BootstrapNav
        class="px-3"
        :items="tabItems"
        :active-route="{title: activeTab}"
        :underline="false"
        @update:active-route="(tab: any) => (activeTab = tab.title)"
      />
      <div class="col-md-12 pt-3">
        <template v-if="activeTab === 'Create New User' && invitationManager">
          <InvitationsForm class="mb-5" data-testid="team-page.create-new-user.section" />
        </template>
        <template v-else-if="activeTab === 'Invitations'">
          <TeamInvitations data-testid="team-page.invitations.section" />
        </template>
        <template v-else-if="activeTab === 'Team Members'">
          <TeamMembers
            class="mb-3"
            :roles="customersStore.userRelationRoles || []"
            data-testid="team-page.team-member.section"
          />
        </template>
      </div>
    </div>
  </section>
</template>
<script setup lang="ts">
import {computed, onMounted, ref} from 'vue';

const loading = ref(true);

const userStore = useUserStore();
const customersStore = useCustomersStore();

const invitationManager = computed(
  () =>
    userStore.user?.is_staff ||
    userStore.user?.groups.some((g) => ['invitation_managers', 'Superaccess'].includes(g.name)),
);

const tabItems = computed(() => {
  const items = [{title: 'Invitations'}, {title: 'Team Members'}];
  if (invitationManager.value) {
    items.unshift({title: 'Create New User'});
  }
  return items;
});
const activeTab = ref(tabItems.value[0].title);

onMounted(async () => {
  await customersStore.readUserRelationRoles();
  loading.value = false;
});
</script>
