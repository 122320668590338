export type Customer = {
  id: number;
  name: string;
  debtor_number: string;
  active: boolean;
  url: string;
  class_name: string;
};

export type CustomerRole = {
  customer: string;
  debtor_number: string;
  name: string;
  role: string;
  email: string;
  linkedin_url: string;
  twitter_url: string;
  phone_number: string;
  active: boolean;
  id: number;
};

export type CustomerEmployee = {
  id: number;
  customer_id: number;
  customer: string;
  debtor_number: string;
  user_id: number;
  username: string;
  role: string;
  created: string;
  active: boolean;
};

/** @see {@link apollo_platform/apps/customers/models.py} > UserRelation.Role */
export type UserRelationRole = {
  name: string;
  value: string;
  internal: boolean;
};

export type UserRelationRoleCreate = {
  user_id: number;
  customer_id: number;
  active: boolean;
  role: string;
};

export type SelectedCustomer = {
  customer: Customer;
  roles: CustomerRole[];
  employees: CustomerEmployee[];
  external_user_relations: CustomerEmployee[];
};

export const useCustomersStore = defineStore('customers', {
  state: () => ({
    selectedCustomer: null as SelectedCustomer | null,
    customers: null as Customer[] | null,
    userRelationRoles: null as UserRelationRole[] | null,
  }),
  actions: {
    setSelectedCustomer(selectedCustomer: SelectedCustomer) {
      const {setItem} = useLocalStorage();
      setItem('selectedCustomer', selectedCustomer);
      this.selectedCustomer = selectedCustomer;
    },
    setCustomers(customers: Customer[]) {
      this.customers = customers;
    },
    setUserRelationRoles(roles: UserRelationRole[]) {
      this.userRelationRoles = roles;
    },
    async readCustomers(search = ''): Promise<Customer[]> {
      const {getItem} = useLocalStorage();
      const localStorageCustomers: Customer[] | null = getItem('customers');
      if (localStorageCustomers) {
        this.setCustomers(localStorageCustomers);
      }
      try {
        // Get the first cursor data only.
        const response = await apiGetCustomers(undefined, undefined, search);
        const data: GenericCursorFn<Customer> = cursor(response.data);
        /**
         * If a search argement was provided, get all search cursor results.
         * Don't setCustomers as search results will remove previously cached customers.
         */
        if (search.length > 0) {
          // Get the next cursor data
          const aggregatedCustomerResults: Customer[] =
            data.next !== null ? await apiGetPaginatedResults(apiGetCustomers, data.next) : [];
          // Return both the first cursor & rest of the aggregated cursors.
          return [...data.results, ...aggregatedCustomerResults];
        } else {
          this.setCustomers(data.results);
          return data.results; // Return the first cursor only.
        }
      } catch (error) {
        throw apiErrorMessage(error);
      }
    },
    async readCustomerById(id: number): Promise<SelectedCustomer> {
      // TODO: this is being hit twice: can we prevent this?
      const {getItem} = useLocalStorage();
      const localStorageSelectedCustomer: SelectedCustomer | null = getItem('selectedCustomer');
      if (localStorageSelectedCustomer) {
        this.setSelectedCustomer(localStorageSelectedCustomer);
      }
      try {
        const {data} = await apiGetCustomer(id);
        this.setSelectedCustomer(data);
        return data;
      } catch (error) {
        throw apiErrorMessage(error);
      }
    },
    async createUserRelationRole(role: UserRelationRoleCreate) {
      try {
        const {data} = await apiPostUserRelationRole(role);
        return data;
      } catch (error) {
        throw apiErrorMessage(error);
      }
    },
    async readUserRelationRoles() {
      try {
        const {data} = await apiGetUserRelationRoles();
        this.setUserRelationRoles(data);
        return data;
      } catch (error) {
        throw apiErrorMessage(error);
      }
    },
    async deleteUserRelationRole(id: string | number) {
      try {
        await apiDeleteUserRelationRole(id);
      } catch (error) {
        throw apiErrorMessage(error);
      }
    },
  },
});
