import translations from './translations.json';

export function translate(language, input) {
  if (language === 'nl-NL' && translations[input]) {
    return translations[input];
  }
  return input;
}

export default {translate};
