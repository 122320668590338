<template>
  <div v-if="authenticated" class="h-100">
    <div class="loader-wrapper" :class="{loderhide: !show}">
      <div class="loader-index">
        <span></span>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script lang="ts" setup>
import {defaultRequest} from '@/authConfig';

const {getItem, removeItem} = useLocalStorage();

const userSettingsStore = useUserSettingsStore();
const userStore = useUserStore();

const msalAuth = useMsalActiveAuth(defaultRequest, defaultRequest);
const show = ref(true);

const authenticated = computed(() => !!msalAuth.authResult.value);

const theme = computed(() => userSettingsStore.userSettings.theme);

onMounted(() => {
  const selectedCustomers = getItem('selectedCustomers');
  if (!selectedCustomers) removeItem('selectedCustomers');

  setTimeout(() => {
    show.value = false;
  }, 1000);
});

watch(
  theme,
  (theme) => {
    const bsTheme = theme === 'dark-only' ? 'dark' : 'light';
    const root = document.querySelector('html') as HTMLHtmlElement;
    root.setAttribute('data-bs-theme', bsTheme);
  },
  {immediate: true},
);

watch(msalAuth.authResult, (newAuth) => {
  userStore.setMsalToken(newAuth);
});
</script>
<style lang="scss">
.loader-wrapper.loderhide {
  display: none;
}
.loader-wrapper {
  background-color: var(--bs-gray-200);
}
[data-bs-theme='dark'] {
  .loader-wrapper {
    background-color: var(--bs-black);
  }
}
</style>
