<template>
  <TooltipDisclosure :title="title" placement="right" :container="props.tooltipContainer">
    <button
      :id="id"
      :class="[
        'menu-icon-button',
        // {'menu-icon-button-home': isHome}, // disable to make home button not always active RND-2152
        {'menu-icon-button-active': active},
      ]"
      type="button"
      :aria-label="title"
    >
      <EkcoIcon v-if="icon === 'ekco'" class="flex-shrink-0" />
      <Icon :icon="icon ? `material-symbols:${icon}` : 'folder'" class="fs-4 flex-shrink-0" />
    </button>
  </TooltipDisclosure>
</template>
<script lang="ts" setup>
interface Props {
  title?: string | undefined;
  icon: string;
  id: string | undefined;
  active?: boolean;
  tooltipContainer?: string;
  isHome?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  title: undefined,
  icon: '',
  id: undefined,
  active: false,
  tooltipContainer: 'body',
  isHome: false,
});
</script>
<style lang="scss" scoped>
.menu-icon-button {
  border: none;
  cursor: pointer;
  width: 38px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease;
  background-color: var(--bs-white);
  box-shadow: var(--bs-box-shadow-sm);
  border-radius: 8px;
  color: var(--bs-gray-600);
}

.menu-icon-button:hover {
  scale: 1.1;
  box-shadow: var(--bs-box-shadow);
}

.menu-icon-button:active {
  transition: scale 0.2s ease;
  scale: 1;
}

.menu-icon-button-active {
  color: var(--bs-white);
  background: var(--ekco-gradient);
}

.menu-icon-button-home {
  color: var(--bs-white);
  background: var(--ekco-gradient);
}

[data-bs-theme='dark'] .menu-icon-button {
  background-color: var(--bs-tertiary-bg);
}
</style>
