let holdingElement: HTMLDivElement | null = null;

/**
 * Get the element in which teleport target elements are temporarily placed
 * until they are used by a Vue component. At that point, the element is moved
 * into the Vue component.
 */
export function getHoldingElement(): HTMLDivElement {
  if (!holdingElement) {
    const newElem = document.createElement('div') as HTMLDivElement;
    newElem.id = 'teleport-target-holding-element';
    newElem.style.display = 'none';
    document.body.appendChild(newElem);
    holdingElement = newElem;
  }

  return holdingElement;
}

/** Get existing or create new teleport target element. */
export function teleportTarget(id: string): HTMLDivElement {
  const exists = document.getElementById(id) as HTMLDivElement | null;
  if (exists) {
    return exists;
  }

  const holdingPlace = getHoldingElement();
  const newElem = document.createElement('div') as HTMLDivElement;
  newElem.id = id;
  holdingPlace.appendChild(newElem);
  return newElem;
}
