import {apolloPlatformAxiosInstance as axiosInstance} from './apolloPlatformAxiosInstance';

/**
 * Customers Endpoints
 */

export const apiGetCustomers = async (
  limit: number = defaultLimit,
  offset: number = defaultOffset,
  search?: string,
) => {
  if (search) {
    return await axiosInstance.get('customer_search', {params: {search}});
  }
  return await axiosInstance.get('customers', {params: {limit, offset}});
};

export const apiGetCustomer = async (id: number) => {
  return await axiosInstance.get(`customers/${id}`);
};

/**
 * N.B: team_roles are not the same as roles from User Management.
 * They are effectively a list of titles for a customer's team contacts.
 * @see {@link apollo_platform/apps/customers/models.py} > UserRelationshipRolesViewSet
 */
export const apiGetUserRelationRoles = async (): Promise<AxiosResponse<UserRelationRole[]>> => {
  return await axiosInstance.get('team_roles');
};

export const apiPostUserRelationRole = async (
  role: UserRelationRoleCreate,
): Promise<AxiosResponse<UserRelationRoleCreate>> => {
  return await axiosInstance.post('team_roles', role);
};

export const apiDeleteUserRelationRole = async (
  id: string | number,
): Promise<AxiosResponse<UserRelationRole>> => {
  return await axiosInstance.delete(`team_roles/${id}/delete`);
};
