const INVITATIONS_KEY = 'invitations';

export interface InvitationCustomer {
  id: number;
  name: string;
  debtor_number: string;
  role: string | null;
}

export interface InvitationRole {
  id: number;
  title: string;
}

export interface InvitationGroup {
  id: number;
  name: string;
}

export interface Invitation {
  id: number;
  email: string;
  created: string;
  sent: string | null;
  accepted: boolean;
  accepted_at: string | null;
  inviter: string;
  customers: InvitationCustomer[];
  roles: InvitationRole[];
  groups: InvitationGroup[];
  url: string;
  key_expired: boolean;
}

export interface TeamRole {
  name: string;
  value: string;
  internal: boolean;
}

export const useTeamStore = defineStore('team', {
  state: () => ({
    invitations: null as Invitation[] | null,
    roles: [] as TeamRole[],
    apiInstance: useApiInstance(),
  }),
  getters: {
    userInvitations: (state) => {
      return (email: string) =>
        state.invitations?.filter((invitation) => invitation.email === email);
    },
  },
  actions: {
    async getRoles() {
      try {
        const {data} = await this.apiInstance.axiosApiInstance({
          method: 'get',
          url: 'team_roles/',
        });
        this.roles = data;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchInvitations() {
      const {getItem} = useLocalStorage();
      if (getItem(INVITATIONS_KEY)) {
        this.setInvitations(getItem(INVITATIONS_KEY));
      }
      await this.apiInstance.fetchAllFromApi('/invitations/').then((result) => {
        this.setInvitations(result);
      });
    },
    setInvitations(invitations: Invitation[] | null) {
      const {setItem} = useLocalStorage();
      setItem(INVITATIONS_KEY, invitations);
      this.invitations = invitations;
    },
  },
});
