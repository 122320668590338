export const useCommonStore = defineStore('common', {
  actions: {
    setError(error) {
      const {setDetailedError} = useErrorStore();
      setDetailedError({
        type: error,
        language: 'en-US',
      });
    },
  },
});
