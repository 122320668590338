<template>
  <MenuIconButtonPresentational
    :id="id"
    :icon="icon"
    :title="title"
    :active="isActive || active"
    :tooltip-container="'#sidebar'"
    :is-home="isHome"
  />
</template>
<script lang="ts" setup>
interface Props {
  icon: string;
  id?: string;
  title?: string;
  to?: string;
  isHome: boolean;
  active?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  icon: 'folder',
  id: undefined,
  title: '',
  to: undefined,
  active: false,
});

const route = useRoute();

// isActive checks if the current icon should be highlighted by splitting
// route and linkPath into segments and comparing the first two segments
// this will allow the icon to be highlighted when the user is in a page
// that is a child of the linkPath and also accounts for if two core names
// are similar such as 'service_catalog' and 'service_catalog_(channel)'
const isActive = computed(() => {
  const linkPath = `/core/${props.to}`;
  const routeSegments = route.path.split('/');
  const linkPathSegments = linkPath.split('/');
  return routeSegments[1] === linkPathSegments[1] && routeSegments[2] === linkPathSegments[2];
});
</script>
