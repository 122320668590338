<template>
  <div :class="sidebarClass()" data-testid="sidebar">
    <section
      class="d-flex flex-column fixed h-100 overflow-y-auto custom-scrollbar"
      data-testid="sidebar.collasped"
    >
      <slot name="collapsed" />
    </section>
    <nav
      id="sidebar"
      data-testid="sidebar.expanded"
      :class="['sidebar-container shadow-lg', {'sidebar-container-collapsed': !isExpanded}]"
    >
      <section class="sidebar-content d-flex flex-column">
        <div
          class="sidebar-head flex-shrink-0 col-auto d-flex align-items-center justify-content-between w-100 md-auto"
          :class="{'d-none': !isExpanded}"
          data-testid="sidebar.expanded.header"
        >
          <slot name="header" />
        </div>

        <!-- Remounting the whole sidebar can be expensive if there are a lot of
          items in the core, instead show/hide using display property. -->
        <div
          :class="[
            'sidebar-scroll flex-grow-1 col custom-scrollbar overflow-y-auto',
            {'d-none': !isExpanded},
          ]"
          data-testid="sidebar.expanded.nav-body"
        >
          <slot name="content" />
        </div>

        <div
          class="sidebar-footer col-auto d-flex align-items-center justify-content-between mt-auto w-100"
          data-testid="sidebar.expanded.footer"
        >
          <slot name="footer" />
        </div>
      </section>
    </nav>
  </div>
</template>
<script lang="ts" setup>
defineEmits<{
  close: [];
  toggle: [];
}>();
const props = defineProps({
  collapsed: {type: Boolean, require: true},
  isCorePage: {type: Boolean, require: true},
  isMobile: {type: Boolean, require: true},
});

// If someone is hovering over the sidebar we force expand it, since the
// collapsed desktop sidebar isn't usable.
const forceExpand = ref(false);
const isExpanded = computed(() => forceExpand.value || !props.collapsed);

const sidebarClass = () => {
  return {
    sidebar: true,
    'open-sidebar-squish': !props.collapsed && props.isCorePage && props.isMobile === false,
  };
};
</script>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.open-sidebar-squish {
  min-width: 22rem;
}

.sidebar-container {
  display: flex;
  flex-shrink: 0;
  z-index: 10;
  height: 100%;
}
.sidebar-content {
  background-color: var(--bs-body-bg);
  width: 100%;
  display: flex;
}

.sidebar-head {
  padding-right: 1rem;
  padding-left: 1rem;
  flex-shrink: 0;
}

.sidebar-footer {
  padding-right: 1rem;
  padding-left: 1rem;
  flex-shrink: 0;
}

.sidebar-body {
  padding: 0.5rem;
}
.sidebar-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
}

/* Mobile */
// @media (min-width: 991.98px) {
.sidebar-head-toggle {
  display: none;
}

.sidebar-background {
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  width: 100vw;
  height: 100%;
}
.sidebar-container {
  position: fixed;
  top: 0;
  left: 0;

  width: 22rem;
  /* Don't want the sidebar to take up the full width, even on narrow screens */
  max-width: 90vw;

  transform: translateX(0%);
  transition: transform 0.3s ease;
}
.sidebar-container-collapsed {
  transform: translateX(-100%) !important;
}

.for-light {
  display: block !important;
}
.for-dark {
  display: none !important;
}

[data-bs-theme='dark'] {
  .for-light {
    display: none !important;
  }
  .for-dark {
    display: block !important;
  }
}
.canvas-nav {
  width: 100%;
  min-width: 52px;
  max-width: 340px;
}
</style>
