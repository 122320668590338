<template>
  <PageLink v-if="item.page.class_name === 'Page'" v-slot="{navigate}" :page="item.page" custom>
    <CoreListGroupItemPresentational
      :class="{'sidebar-item-disabled': props.disabled}"
      :item="item"
      :active="route.meta.activePageNode?.page?.id === item.page.id"
      @click="navigate"
    />
  </PageLink>
  <div v-else>
    <CoreListGroupItemPresentational
      :class="{
        'sidebar-item-disabled': props.disabled,
        'pb-1': !item.collapsed && topLevel,
      }"
      :item="item"
      @collapse="emits('collapse', item)"
    >
      <slot></slot>
    </CoreListGroupItemPresentational>
  </div>
</template>
<script lang="ts" setup>
import {TreeNodePage} from '@/stores/apolloPlatform/cores/portalNavigation';

interface Props {
  item: TreeNodePage;
  disabled?: boolean;
  topLevel?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  topLevel: false,
});

const emits = defineEmits(['collapse']);

const route = useRoute();
</script>

<style lang="scss" scoped>
.sidebar-item-disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.75;
}

.available {
  cursor: pointer;
}
</style>
