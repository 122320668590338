import {apolloPlatformAxiosInstance as axiosInstance} from './apolloPlatformAxiosInstance';
export enum ContentType {
  Document = 'document',
  File = 'file',
  Report = 'report',
}

export interface SearchResponse {
  page: number;
  page_size: number;
  total_results: number;
  results: SearchResult[];
}

export interface SearchResult {
  id: number;
  title: string;
  content_link: string | null;
  type: ContentType;
}
export const apiSearch = async (
  query: string,
  page?: number,
  page_size?: number,
  content_type?: ContentType,
): Promise<SearchResponse> => {
  const response = await axiosInstance.get('search', {
    params: {query, page, page_size, content_type},
  });
  return response.data;
};
