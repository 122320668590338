<template>
  <section class="container-fluid h-100" style="max-width: 768px">
    <h1 data-testid="news-&-updates.title">{{ translateText('News & Updates') }}</h1>
    <div class="d-flex flex-column w-100 w-lg-75" data-testid="news-&-updates.card-container">
      <div v-for="newsItem in newsItems" :key="newsItem.id" class="card w-100 mb-3">
        <div class="card-body">
          <h5 class="card-title">
            <router-link :to="getNewsItemLink(newsItem)" class="link-body-emphasis">
              {{ newsItem.title }}
            </router-link>
          </h5>
          <p>{{ newsItem.description }}</p>
          <router-link :to="getNewsItemLink(newsItem)">
            <small>
              <p>Read more...</p>
            </small>
          </router-link>
          <footer>
            <em>{{ getPublicationDate(newsItem) }}</em>
          </footer>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import {mapState} from 'pinia';
import translate from '@/translate';
import {useApiInstance} from '@/composables/apiInstance.js';

export default {
  name: 'NewsPage',
  components: {},
  setup() {
    const {axiosApiInstance} = useApiInstance();
    return {axiosApiInstance};
  },
  data() {
    return {newsItems: [], next: null, previous: null};
  },
  computed: {
    ...mapState(useUserSettingsStore, ['userSettings']),
    language() {
      return this.userSettings.language;
    },
    ...mapState(useCustomersStore, ['selectedCustomer']),
  },
  watch: {
    language: {
      immediate: false,
      async handler() {
        await this.getNewsItems();
      },
    },
  },
  async created() {
    await this.getNewsItems();
  },
  methods: {
    translateText(input) {
      return translate.translate(this.language, input);
    },
    getPublicationDate(newsItem) {
      const date = new Date(Date.parse(newsItem.publication_date));
      return date.toLocaleDateString(undefined, {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    },
    async getNewsItems(cursor) {
      if (this.selectedCustomer === null) {
        this.$router.push('/error');
      }
      const config = {
        method: 'GET',
        url: 'news/',
        params: cursor ? {cursor} : {},
      };
      await this.axiosApiInstance(config).then((response) => {
        this.newsItems = response.data.results;
        this.next =
          response.data.next !== null
            ? new URL(response.data.next).searchParams.get('cursor')
            : null;
        this.previous =
          response.data.previous !== null
            ? new URL(response.data.previous).searchParams.get('cursor')
            : null;
      });
    },
    getNewsItemLink(item) {
      return {
        name: 'newsitem',
        params: {
          id: item.id,
        },
      };
    },
  },
};
</script>
