/**
 * File manager path handling utilities.
 */

/**
 * Parsed file path.
 */
export type PathSpec = {
  parts: string[];
  fileName: string | undefined;
};

export function makePathSpec(pathString: string): PathSpec {
  const parts = pathString.split('/').filter((p) => p.length);
  let fileName = undefined;
  if (!pathString.endsWith('/') && parts.length && parts[parts.length - 1].includes('.')) {
    fileName = parts.pop();
  }

  return {parts, fileName};
}

export function pathSpecToCanonicalDir(pathSpec: PathSpec): string {
  if (pathSpec.parts.length === 0) {
    return '/';
  }
  return '/' + pathSpec.parts.join('/') + '/';
}

export function pathSpecToCanonicalFull(pathSpec: PathSpec): string {
  return pathSpecToCanonicalDir(pathSpec) + (pathSpec.fileName ?? '');
}

export function pathSpecPop(pathSpec: PathSpec, count: number): PathSpec {
  if (count <= 0) return {...pathSpec};
  return {
    ...pathSpec,
    parts: pathSpec.parts.slice(0, -count),
    fileName: undefined,
  };
}
