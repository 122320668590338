<template>
  <Teleport to="body">
    <Transition
      name="fade-container"
      appear
      v-bind="$attrs"
      @after-enter="showPanel = true"
      @before-leave="showPanel = false"
    >
      <div
        v-show="open"
        :class="{'header-layout-inset': fullHeight === false}"
        style="position: relative; z-index: 10"
      >
        <section
          class="dialog-container"
          :style="{
            'background-color': overlay === true ? 'rgb(0, 0, 0, 0.25)' : 'transparent',
          }"
          data-testid="slide-in.dialog-container"
          @click="showPanel = false"
        >
          <div
            :class="['dialog', slideInLeft === true ? 'dialog-left' : 'dialog-right']"
            data-testid="slide-in"
          >
            <Transition
              class="fade-panel"
              :name="slideInLeft === true ? 'fade-panel-left' : 'fade-panel-right'"
              @after-leave="$emit('close')"
            >
              <article v-if="showPanel" class="custom-scrollbar dialog-panel" @click.stop>
                <h4 class="border-bottom mx-4 pb-2 mb-4" data-testid="slide-in.title">
                  <slot name="header"></slot>
                </h4>
                <form
                  ref="form"
                  novalidate
                  class="d-flex flex-column justify-content-between needs-validation"
                  data-testid="slide-in.form"
                  @submit="submit"
                >
                  <div class="flex-grow-1 mx-4 mb-4">
                    <slot></slot>
                  </div>
                  <div class="d-flex mx-4">
                    <slot name="buttons"></slot>
                  </div>
                </form>
              </article>
            </Transition>
          </div>
        </section>
      </div>
    </Transition>
  </Teleport>
</template>
<script setup>
const emits = defineEmits(['close', 'submit']);

defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  overlay: {
    type: Boolean,
    default: true,
  },
  slideInLeft: {
    type: Boolean,
    default: false,
  },
  fullHeight: {
    type: Boolean,
    default: true,
  },
});

// Used to trigger the panel transition
const showPanel = ref(false);
const form = ref();

defineExpose({
  form,
});

// https://getbootstrap.com/docs/4.6/components/forms/?#how-it-works
const submit = (event) => {
  if (form.value.checkValidity() === false) {
    event.preventDefault();
    event.stopPropagation();
  } else {
    event.preventDefault();
    emits('submit');
  }
  form.value.classList.add('was-validated');
};
</script>
<style lang="scss" scoped>
.fade-container-enter-active,
.fade-container-leave-active {
  transition: opacity 0.3s ease;
}

.fade-container-enter-from,
.fade-container-leave-to {
  opacity: 0;
}

.dialog-container {
  position: fixed;
  inset: 0; // Height of header
  overflow-y: auto;
}

.dialog {
  display: flex;
  top: 0;
  bottom: 0;
  position: fixed;
  width: 100vw;
  max-width: 28rem;
}

@media (min-width: 768px) {
  .header-layout-inset .dialog-container {
    inset: 65px 0 0; // Height of header
  }

  .header-layout-inset .dialog {
    top: 65px; // Height of header
  }
}

.dialog-left {
  padding-right: 2.5rem;
  left: 0;
}

.dialog-right {
  padding-left: 2.5rem;
  right: 0;
}

.fade-panel-left-enter-active,
.fade-panel-right-enter-active,
.fade-panel-left-leave-active,
.fade-panel-right-leave-active {
  transition: all 0.5s ease-in-out;
}

.fade-panel-left-enter-from,
.fade-panel-left-leave-to {
  transform: translateX(-100%);
}

.fade-panel-right-enter-from,
.fade-panel-right-leave-to {
  transform: translateX(100%);
}

.fade-panel-left-enter-to,
.fade-panel-left-leave-from {
  transform: translateX(0px);
}

.fade-panel-right-enter-to,
.fade-panel-right-leave-from {
  transform: translateX(0px);
}

.fade-panel {
  max-width: 28rem;
  width: 100vw;
  pointer-events: auto;
}

.dialog-panel {
  display: flex;
  background-color: var(--bs-tertiary-bg);
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  overflow-y: auto;
  flex-direction: column;
  height: 100%;
}
</style>
