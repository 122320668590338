<template>
  <VueMultiSelectSearch
    v-if="model"
    v-model="model"
    input-group
    name="customer-vue-multiselect-search"
    :options="options"
    label="name"
    track-by="debtor_number"
    only-input
    placeholder="Type to search for a customer"
    :loading="loading"
    :max-height="500"
    style="width: 25rem"
    @search="(value: string) => debouncedFn(value)"
  >
    <template #noResult>
      <span> No customers found.</span>
    </template>
    <template #afterList>
      <aside class="d-flex align-items-center border-top p-2 bg-body-tertiary text-secondary">
        <span class="d-flex align-items-center me-3">
          <Icon icon="material-symbols:arrow-upward" :inline="true" style="margin-right: -0.2rem" />
          <Icon icon="material-symbols:arrow-downward" :inline="true" class="me-1" />
          Navigate
        </span>
        <span class="d-flex align-items-center me-4">
          <Icon icon="material-symbols:keyboard-return" :inline="true" class="me-1" />
          Select
        </span>
        <span class="d-flex align-items-center me-3">
          <Icon icon="material-symbols:search" :inline="true" class="me-1" />
          Type to search
        </span>
      </aside>
    </template>
  </VueMultiSelectSearch>
  <p v-else class="placeholder-glow mb-0" style="width: 30rem">
    <span
      class="placeholder placeholder-lg col-12 rounded-3"
      data-testid="header.customer-select.placeholder"
      style="height: 2.5rem"
    ></span>
  </p>
</template>
<script lang="ts" setup>
/*
 * It would be wise to phase out this component at some stage as it was created
 * without a design due to urgency.
 * TODO: ask Joseph to design a customer search dialog.
 */
import {useDebounceFn} from '@vueuse/core';
import {
  makePathSpec,
  pathSpecToCanonicalFull,
} from '@/components/collabcentre/organisms/FileManager/pathSpec';

const customersStore = useCustomersStore();
const storage = useLocalStorage();

const router = useRouter();
const route = useRoute();

const loading = ref(false);

const readCustomerById = async (customer: Customer) => {
  loading.value = true;
  try {
    await customersStore.readCustomerById(customer.id);
    storage.removeItem('sphere-pages');

    // Use a clean path to avoid automatically triggering previous file download (RND-2345)
    let newPath = makePathSpec(String(route.hash).replace('#/', '')) as any;
    newPath = pathSpecToCanonicalFull({...newPath, fileName: undefined});
    if (['', '/'].includes(newPath)) {
      await router.push({hash: ''});
    } else {
      await router.push({hash: `#${newPath}`});
    }

    router.go(0);
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
};

const model = computed({
  get: () =>
    customersStore.selectedCustomer !== null ? customersStore.selectedCustomer.customer : undefined,
  set: (value: any) => value && readCustomerById(value),
});

const options = computed(() => customersStore.customers || []);

const searchCustomers = async (value: string) => {
  loading.value = true;
  try {
    // Fetch customers using search text
    const customers: Customer[] = await customersStore.readCustomers(value);
    // Merge results with options model(i.e customersStore.customers)
    const duplicateCustomers: Customer[] = [...options.value, ...customers];
    // https://stackoverflow.com/questions/2218999/how-to-remove-all-duplicates-from-an-array-of-objects
    const uniqueCustomers: Customer[] = duplicateCustomers.filter(
      (e: Customer, i: number, a: Customer[]) =>
        i === a.findIndex((t: Customer) => t.id === e.id && t.name === e.name), // element, index, array
    );
    // Set the new de-duplicated customers
    await customersStore.setCustomers(uniqueCustomers);
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
};

// When introducting debouncers, consult the team first. They're dangerous.
const debouncedFn = useDebounceFn(
  async (value: string) => {
    await searchCustomers(value);
  },
  1000,
  {maxWait: 5000},
);
</script>
