<template>
  <div :style="outerStyle()">
    <div
      id="extended-button"
      tabindex="0"
      role="button"
      :style="innerStyle()"
      data-testid="sidebar.expanded.header.collapse.button"
      @click="emit('click')"
    >
      <Icon :icon="type" />
    </div>
  </div>
</template>

<script setup lang="ts">
import {StyleValue} from 'vue';

export type ExtendSide = 'top' | 'bottom' | 'left' | 'right';

const props = defineProps<{type: string; size: number; side: ExtendSide}>();
const emit = defineEmits<{click: []}>();

function outerStyle(): StyleValue {
  const style: StyleValue = {
    position: 'absolute',
  };
  style[props.side] = `${-props.size / 2}px`;
  return style;
}

function innerStyle(): StyleValue {
  return {
    width: `${props.size}px`,
    height: `${props.size}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    color: 'white',
    backgroundImage: 'linear-gradient(to right, #035BFF, #0BF7F2)',
    cursor: 'pointer',
  };
}
</script>
