function removeHtmlTags(text) {
  return text
    .replace(/<small>.*?<\/small>/g, '')
    .replace(/<[^>]*>/g, '')
    .replace(/([.,])(?=[^\s])/g, '$1 ');
}

export const useNewsStore = defineStore('news', () => {
  const axiosApiInstance = useApiInstance().axiosApiInstance;

  const newsItems = ref([]);
  const newsLoading = ref(false);
  const rssArticles = ref([]);
  const rssLoading = ref(false);

  const addRSSNewsItemArticle = (feedName, rssArticle) => {
    // if rssArticle is not already in newsItems add it
    if (newsItems.value.some((item) => item.title === rssArticle.title)) {
      return;
    }

    let newsItem = {
      id: rssArticle.id,
      title: rssArticle.title,
      description: removeHtmlTags(rssArticle.summary),
      link: rssArticle.link,
      publication_date: isNaN(new Date(rssArticle.published).getTime())
        ? null
        : new Date(rssArticle.published).toISOString(), // Added handling for invalid date (RND-2182)
      source: rssArticle.title_detail.base,
      name: feedName,
    };
    newsItems.value.push(newsItem);
  };

  const getRssList = async () => {
    if (rssLoading.value) return;

    rssLoading.value = true;
    try {
      let config = {
        method: 'GET',
        url: 'rss_feeds/',
      };

      rssArticles.value = [];

      const feedsResp = await axiosApiInstance(config);
      const promises = feedsResp.data.map(async (feed) => {
        let config = {
          method: 'GET',
          url: 'rss_feeds/' + feed.id + '/',
        };

        const response = await axiosApiInstance(config);
        for (const article of response.data) {
          const exists = rssArticles.value.some((item) => item.title === article.title);
          if (!exists) {
            rssArticles.value.push(article);
          }
        }

        for (const article of response.data) {
          addRSSNewsItemArticle(feed.name, article);
        }
      });

      await Promise.all(promises);
    } finally {
      rssLoading.value = false;
    }
  };

  const loadRecentNews = async () => {
    if (newsLoading.value) return;

    newsLoading.value = true;
    try {
      let config = {
        method: 'GET',
        url: 'news/',
        params: {page_size: 3},
      };
      const response = await axiosApiInstance(config);
      newsItems.value = response.data.results;
    } catch (error) {
      console.log('News error', error);
      newsItems.value = [];
    } finally {
      newsLoading.value = false;
    }
  };

  //clear all newsitems and load them again
  const refreshNews = async () => {
    await Promise.all([getRssList(), loadRecentNews()]);
  };

  return {
    newsItems,
    newsLoading,
    rssArticles,
    rssLoading,
    getRssList,
    loadRecentNews,
    refreshNews,
  };
});
