<template>
  <div class="h-100 p-0 m-0 w-100">
    <div
      class="container w-100"
      style="
        height: 100vh;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10%;
      "
      data-testid="error-page.message.container"
    >
      <div
        id="error-page-box"
        class="card card-animation"
        style="padding: 10%; width: auto; height: auto"
        data-testid="error-page.message.card"
      >
        <div class="mb-3"><LogoEkco /></div>
        <title data-testid="error-page.message.title">{{ translateText(title) }}</title>
        <h3 data-testid="error-page.message.title.h3" style="padding-bottom: 10px">
          {{ translateText(heading) }}
        </h3>
        <h4
          data-testid="error-page.message.user-email"
          style="padding-bottom: 50px; font-size: 20px; white-space: wrap"
        >
          {{ user ? user.email : 'Unsuccessful authentication' }}
        </h4>
        <br />
        <div v-for="text in texts" :key="text" data-testid="error-page.message.text">
          <p>{{ translateText(text) }}</p>
        </div>
        <div class="pt-3 d-flex flex-row">
          <BootstrapButton
            name="submit-button"
            class="me-2"
            type="submit"
            data-testid="error-page.message.button.log-out"
            @click="logout"
          >
            <Icon
              icon="material-symbols:login"
              class="me-1"
              data-testid="error-page.message.button.log-in"
            />
            {{ translateText('Log out') }}
          </BootstrapButton>
          <BootstrapButton
            name="submit-button"
            class="me-2"
            type="submit"
            data-testid="error-page.message.button.log-out"
            @click="feedbackOpen"
          >
            <Icon
              icon="material-symbols:report"
              class="me-1"
              data-testid="error-page.message.button.log-in"
            />
            {{ translateText('Report Issue') }}
          </BootstrapButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import translate from '@/translate';
import config from '@/config';
import {
  feedbackOpenKey,
  FeedbackOpen,
} from '@/components/shared/molecules/FeedbackSlideIn/injectionKeys';
import BootstrapButton from '@/components/shared/bootstrap/BootstrapButton/BootstrapButton.vue';

const msal = useMsal();

const userSettingsStore = useUserSettingsStore();
const userStore = useUserStore();
const errorStore = useErrorStore();
const localStorage = useLocalStorage();

const title = ref('');
const heading = ref('');
const texts = ref<string[]>([]);

const user = computed(() => userStore.user);
const error = computed(() => errorStore.error);
const language = computed(() => userSettingsStore.userSettings.language);

const translateText = (input: string) => {
  return translate.translate(language.value, input);
};

const logout = async () => {
  await msal.instance.logoutRedirect({
    account: msal.instance.getAllAccounts()[0],
    postLogoutRedirectUri: config.REDIRECT_URL,
  });
};

const feedbackOpen = inject<FeedbackOpen>(feedbackOpenKey) as FeedbackOpen;

onMounted(() => {
  localStorage.removeItem('selectedCustomers');

  const errorType = error.value?.type;

  if (errorType === 'customerInvitation') {
    title.value = 'Oops... There seems to be a problem...';
    heading.value = 'Oops... There seems to be a problem...';
    texts.value = [
      'There are no customers or invitations configured for this user.',
      'Want to know more? Use the report issue button to request access.',
    ];
  } else if (errorType === 'unknownUser') {
    title.value = 'Oops... There seems to be a problem...';
    heading.value = 'Oops... There seems to be a problem...';
    texts.value = [
      'You are not yet known in the Ekco Sphere',
      'This is probably because you have not been invited by your organisation.',
      'Want to know more? Use the report issue button to request access.',
    ];
  } else if (errorType === 'duplicate_profile') {
    title.value = 'Login issue...';
    heading.value = 'Login issue...';
    texts.value = [
      "A different account exists with matching profile info that's supposed to be unique.",
      'Make sure you use the same login provider as the previous time you logged in.',
      'Use the report issue button if you think this is an issue on our side, or if you migrated to a different login provider.',
    ];
  } else {
    // Unknown error
    title.value = 'Oops... There seems to be a problem...';
    heading.value = 'Oops... There seems to be a problem...';
    texts.value = [
      'There was an error while loading the page.',
      'Please try again later.',
      'Want to know more? Use the report issue button to request access.',
    ];
  }
});
</script>

<style scoped lang="scss">
.card {
  animation:
    slide-in 0.5s ease-in-out,
    fade-in 0.5s ease-in-out;
  animation-iteration-count: 1;
}

@keyframes slide-in {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
