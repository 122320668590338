import {AuthError, AuthenticationResult, RedirectRequest, SilentRequest} from '@azure/msal-browser';
import {useMsalAuthentication} from './useMsalAuthentication';

/** Composable that returns authentication info that is automatically refreshed */
export function useMsalActiveAuth(mainRequest: RedirectRequest, request: SilentRequest) {
  const authError = ref<AuthError>();
  const authInProgress = ref(false);
  const authResult = ref<AuthenticationResult>();
  const mounted = ref(false);
  const timeoutId = ref<number>();

  const {acquireToken} = useMsalAuthentication(mainRequest);

  const doAuth = async () => {
    if (authInProgress.value || !mounted.value) return;

    authInProgress.value = true;
    try {
      authResult.value = await acquireToken(request);

      // Auth successful, setup reauth
      if (authResult.value.expiresOn && mounted.value) {
        const nowT = new Date().getTime();
        const expiresIn = authResult.value.expiresOn.getTime() - nowT;
        timeoutId.value = window.setTimeout(
          () => {
            doAuth();
          },
          expiresIn - 10 * 1000,
        );
      }
    } catch (e) {
      authResult.value = undefined;
      authError.value = e as AuthError;
    } finally {
      authInProgress.value = false;
    }
  };

  onMounted(() => {
    mounted.value = true;
    doAuth();
  });

  onUnmounted(() => {
    mounted.value = false; // Break out of reauth loop
    if (timeoutId.value) {
      clearTimeout(timeoutId.value);
    }
  });

  return {authError, authInProgress, authResult};
}
