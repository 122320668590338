import {
  AuthenticationResult,
  BrowserAuthError,
  InteractionRequiredAuthError,
  InteractionStatus,
  RedirectRequest,
  SilentRequest,
} from '@azure/msal-browser';
import {MsalContext, useMsal} from './useMsal';

function withAccount(msal: MsalContext, request: SilentRequest): SilentRequest | undefined {
  const newReq = {...request};
  if (newReq.account) return newReq;

  newReq.account = msal.accounts.value[0];

  if (msal.accounts.value.length === 1) {
    newReq.account = msal.accounts.value[0];
    return newReq;
  } else if (msal.accounts.value.length > 1) {
    /**
     * Fix for multiple accessTokens, need to wait for users to report again
     * Bug appeared where two uses had two access tokens, one out of date
     */
    let highest: number = 0;
    let highestAccount = undefined;

    for (const account of msal.accounts.value) {
      const expiry = account.idTokenClaims?.exp;
      if (expiry !== undefined && expiry > highest) {
        highest = expiry;
        highestAccount = account;
      }
    }
    newReq.account = highestAccount;
    return newReq;
  }
  return undefined;
}

function isInteractionRequiredError(error: any): boolean {
  if (error instanceof InteractionRequiredAuthError) return true;

  if (error instanceof BrowserAuthError) {
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md#x-frame-options-deny
    // > Some B2C flows are expected to throw this error due to their need for
    // > user interaction.
    //
    // We can't differentiate between an expected error from the B2C flow, or
    // a different error. We have to assume it's caused by the B2C flow because
    // else people won't be able to log in.
    //
    // Important! If this is caused by a different issue then the user may
    // enter an infinite redirect loop. You can open the iframe URL in a new
    // tab to make B2C/AAD return the real error. (It'll appear in the address
    // bar.)
    return error.errorCode === 'monitor_window_timeout';
  }

  return false;
}

export function useMsalAuthentication(mainRequest: RedirectRequest) {
  const msal = useMsal();

  const acquireToken = async (request?: SilentRequest): Promise<AuthenticationResult> => {
    if (
      msal.inProgress.value === InteractionStatus.Startup ||
      msal.inProgress.value === InteractionStatus.HandleRedirect
    ) {
      const response = await msal.instance.handleRedirectPromise();
      if (response) return response;
    }

    request = request || mainRequest;
    request = withAccount(msal, request);
    try {
      if (request) return await msal.instance.acquireTokenSilent(request);
    } catch (error) {
      if (!isInteractionRequiredError(error)) {
        throw error;
      }
    }

    // Fallback to redirect auth if we didn't attempt a silent call or it
    // failed with an 'interaction required' error.

    await msal.instance.acquireTokenRedirect(mainRequest);

    // acquireTokenRedirect is supposed to navigate away from the current
    // browser window. If it returns, that would be a bug in MSAL.
    // The `throw` here is just to stop TypeScript from complaining about a
    // missing final return.
    throw Error('acquireTokenRedirect should never return.');
  };

  return {acquireToken};
}
