<template>
  <section class="list-group-item">
    <MiscListGroupItemPresentational :id="id" :icon="icon" @click="emits('click')">
      <div class="d-flex justify-content-between align-items-center w-100">
        <span class="mt-1 item-button-title">{{ title }}</span>
        <slot name="content-right"></slot>
      </div>
    </MiscListGroupItemPresentational>
  </section>
</template>

<script lang="ts" setup>
interface Props {
  id: string;
  icon?: string;
  title: string;
}

defineProps<Props>();

const emits = defineEmits(['click']);
</script>

<style lang="scss" scoped>
.item-button-title {
  font-family: Rubik, sans-serif;
  color: var(--bs-blue-900);
  padding-left: 10px;
}

[data-bs-theme='dark'] {
  .item-button-title {
    color: var(--bs-white);
  }
}
</style>
