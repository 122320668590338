<template>
  <div class="card">
    <div class="card-header m-0 px-2 py-3" style="padding: 0.75">
      <ErrorComponent v-if="errorMessage" class="m-0 pb-2" :errors="[errorMessage]" />
      <h6 class="m-0" data-testid="team-page.team-member.section.heading">Team Members</h6>
    </div>
    <BootstrapFormControl
      v-model="searchQuery"
      class="w-25 p-1"
      name="team-member-filter"
      placeholder="Type username to search..."
      only-input
      size="sm"
    />
    <table
      class="table table-striped table-hover table-borderless rounded px-5"
      data-testid="team-page.team-member.table"
    >
      <thead data-testid="team-page.team-member.table.thead">
        <tr>
          <th v-for="(name, index) in colsNames" :key="index" class="fw-normal">
            {{ name }}
          </th>
        </tr>
      </thead>
      <tbody class="table-group-divider" data-testid="team-page.team-member.tbody">
        <tr
          v-if="userRelationsLoaded && filteredSortedRelations.length === 0"
          data-testid="team-page.team-member.tbody.team-member-item.none.placeholder"
        >
          <td colspan="999">
            <span class="d-flex justify-content-center">No team members found</span>
          </td>
        </tr>
        <template v-else-if="!userRelationsLoaded && filteredSortedRelations.length === 0">
          <tr
            v-for="item in placeholderNum(5, 9)"
            :key="item"
            data-testid="team-page.team-member.tbody.team-member-item.loading.placeholder"
          >
            <td v-for="col in colsNames" :key="col" class="placeholder-glow">
              <span class="placeholder w-100"></span>
            </td>
          </tr>
        </template>
        <template v-else>
          <tr
            v-for="relation in currentPageRelations"
            :key="relation.id"
            data-testid="team-page.team-member.tbody.team-member.item"
            :data-testrowid="relation.id"
          >
            <td data-testid="team-page.team-member.tbody.team-member.item.user-email">
              {{ relation.username }}
            </td>
            <td
              data-hj-suppress
              data-testid="team-page.team-member.tbody.team-member.item.customer"
            >
              {{ relation.customer }}
            </td>
            <td data-testid="team-page.team-member.tbody.team-member.item.user-role">
              <div class="d-flex justify-content-between">
                <span v-if="staff || roleManager || superAccess" class="d-flex w-100">
                  <VueMultiSelectSearch
                    name="team-role-vue-multiselect-search"
                    :model-value="getUserRoles(relation.user_id)"
                    :input-group="false"
                    only-input
                    track-by="value"
                    label="value"
                    :options="roleOptions"
                    :close-on-select="true"
                    :allow-empty="false"
                    :multiple="true"
                    class="me-2 mb-0 w-100"
                    :max-height="500"
                    data-testid="team-page.team-member.tbody.team-member.item.role-manger.user-role.select"
                    @select="(option: Role) => selectRole(option, relation)"
                    @remove="(option: Role) => deleteRole(option, relation)"
                  />
                </span>
                <span
                  v-for="(role, index) in getUserRoles(relation.user_id)"
                  v-else
                  :key="role.name"
                  class="me-1"
                  :data-testid="
                    'team-page.team-member.tbody.team-member.item.user-role.' +
                    role.name.toLocaleLowerCase().replaceAll(' ', '-')
                  "
                >
                  {{
                    index + 1 !== getUserRoles(relation.user_id).length
                      ? `${role.value},`
                      : role.value
                  }}
                </span>
                <IconButton
                  v-if="staff || invitationManager || superAccess"
                  icon-name="material-symbols:delete-outline"
                  class="text-danger"
                  data-testid="team-page.team-member.tbody.team-member.item.role-manger.button.delete"
                  @keypress.stop
                  @click.stop="removeUser(relation)"
                />
              </div>
            </td>
          </tr>
        </template>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="999">
            <PaginationComponent
              :count="count"
              :total="total"
              :limit="PAGE_SIZE"
              @update-cursor="updatePage"
            />
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>
<script setup lang="ts">
import {CustomerEmployee} from '@/stores/apolloPlatform/apolloPlatform';
import {TeamRole} from '@/stores/apolloPlatform/team/team';

const PAGE_SIZE = 20;

interface Props {
  roles: TeamRole[];
}

const props = defineProps<Props>();

interface Role {
  name: string;
  value: string;
  relation_id?: number;
}

const {axiosApiInstance} = useApiInstance();
const {placeholderNum} = usePlaceholderNum();

// Stores
const customersStore = useCustomersStore();
const userStore = useUserStore();

// Store methods
const {readCustomerById} = customersStore;

// Store refs
const {selectedCustomer} = storeToRefs(customersStore);
const {user} = storeToRefs(userStore);

/**
 * Readonly object for mapping between <th> display names and row data object keys
 */
const colsMap = readonly<{[key: string]: string}>({
  Username: 'username',
  Customer: 'customer',
  UserRole: 'role',
});
const colsNames = ref<string[]>(Object.keys(colsMap));
const loading = ref(false);
const loadingRelationId = ref();
const errorMessage = ref();

const userRelationsLoaded = computed(() => selectedCustomer.value !== null);
const internalUserRelations: globalThis.ComputedRef<CustomerEmployee[]> = computed(
  () => selectedCustomer.value?.employees || [],
);
const externalUserRrelations: globalThis.ComputedRef<CustomerEmployee[]> = computed(
  () => selectedCustomer.value?.external_user_relations || [],
);
const staff = computed(() => user.value?.is_staff);
const invitationManager = computed(() =>
  user.value?.groups.some(({name}) => name === 'invitation_managers'),
);
const roleManager = computed(() => user.value?.groups.some(({name}) => name === 'role_managers'));
const superAccess = computed(() => user.value?.groups.some(({name}) => name === 'Superaccess'));
const roleOptions = computed(() => {
  const allRoles = props.roles.filter(({value}) => value !== 'Ekco User');
  return roleManager.value === true || superAccess.value === true
    ? allRoles
    : allRoles.filter(({value}) => value === 'Employee' || value == 'MetricsConsumer');
});

/**
 * Table customers_userrelation may have many users assigned to many customer roles
 */

// Search filter string
const searchQuery = ref<string>('');

const allUserRelations: globalThis.ComputedRef<CustomerEmployee[]> = computed(() => {
  return roleManager.value === true || superAccess.value === true
    ? [...internalUserRelations.value, ...externalUserRrelations.value]
    : internalUserRelations.value;
});
const uniqueUserRelations: globalThis.ComputedRef<CustomerEmployee[]> = computed(() => [
  ...new Map(allUserRelations.value?.map((item) => [item['username'], item])).values(),
]);
const sortedUserRelations: globalThis.ComputedRef<CustomerEmployee[]> = computed(() => {
  return uniqueUserRelations.value.sort((a, b) => a.username.localeCompare(b.username));
});
// Sorted relations after the search filter has been applied
const filteredSortedRelations = computed(() => {
  if (searchQuery.value === '') {
    return sortedUserRelations.value;
  }

  return sortedUserRelations.value.filter((u) =>
    u.username.toLowerCase().includes(searchQuery.value.toLowerCase()),
  );
});

// The total number of relations
const total = computed(() => filteredSortedRelations.value.length || 0);
// The total number of pages
const count = computed(() => Math.floor((total.value + PAGE_SIZE - 1) / PAGE_SIZE));
// Current page number
const page = ref(1);

// Retrieve all relations to display on the current page
const currentPageRelations = computed(() => {
  const startIndex = (page.value - 1) * PAGE_SIZE;
  const endIndex = startIndex + PAGE_SIZE;
  return filteredSortedRelations.value.slice(startIndex, endIndex);
});

const getUserRoles = (user_id: number): Role[] => {
  const userRelations = allUserRelations.value?.filter((role) => role.user_id === user_id);
  const userRelationsRoleNames = userRelations.map(({id, role}) => ({
    id,
    roleName: role,
  }));
  return userRelationsRoleNames.map(({id, roleName}) => {
    const role = roleOptions.value.find((r) => r.value === roleName);
    if (!role) throw Error(`Role is required`);
    return {
      ...role,
      relation_id: id,
    };
  });
};

const getUserRoleToUpdate = (option: Role, userRelation: CustomerEmployee) => {
  // A user can have both internal roles simultaneously, but only one external role

  // Check if the selected option is an internal role
  const internalRoles = ['Employee', 'MetricsConsumer'];
  const isInternal = internalRoles.includes(option.value);

  // Get the relations for this user
  const matchedUserRelations = allUserRelations.value.filter(
    ({user_id}) => user_id === userRelation.user_id,
  );

  // If the selected option is internal, that's the one we want to update
  // Otherwise, we want to ignore the internal roles the user already has
  return isInternal
    ? matchedUserRelations.find(({role}) => role === option.value)
    : matchedUserRelations.find(({role}) => role !== 'Employee' && role !== 'MetricsConsumer');
};

const selectRole = async (option: Role, userRelation: CustomerEmployee) => {
  loading.value = true;
  loadingRelationId.value = userRelation.id;
  const userRoleToUpdate = getUserRoleToUpdate(option, userRelation);
  try {
    // If there is a user relation that matches the role, use that, if not create a new relation
    if (userRoleToUpdate) {
      // Update the user relation
      await userStore.updateUserRole({
        user_id: userRelation.user_id,
        customer_id: userRelation.customer_id,
        relation_id: userRoleToUpdate?.id,
        role: option.name,
      });
    } else {
      // Create the user relation
      await axiosApiInstance({
        method: 'post',
        url: 'team_roles',
        data: {
          user_id: userRelation.user_id,
          customer_id: userRelation.customer_id,
          active: true,
          role: option.name,
        },
      });
    }
    await readCustomerById(selectedCustomer.value!.customer.id);
    errorMessage.value = undefined;
  } catch (error: any) {
    errorMessage.value = error.message;
  } finally {
    loading.value = false;
  }
};

const deleteRole = async (option: Role, userRelation: CustomerEmployee) => {
  loading.value = true;
  loadingRelationId.value = userRelation.id;
  const userRoleToUpdate = getUserRoleToUpdate(option, userRelation);
  const url = userRoleToUpdate
    ? `team_roles/${userRoleToUpdate.id}/delete`
    : `team_roles/${userRelation.id}/delete`;
  try {
    await axiosApiInstance({
      method: 'delete',
      url,
    });
    await readCustomerById(selectedCustomer.value!.customer.id);
    errorMessage.value = undefined;
  } catch (error: any) {
    errorMessage.value = error.message;
  } finally {
    loading.value = false;
  }
};

const removeUser = async (userRelation: CustomerEmployee) => {
  try {
    await userStore.deleteUserCustomerRelation(userRelation.user_id, userRelation.customer_id);
    await readCustomerById(selectedCustomer.value!.customer.id);
    errorMessage.value = undefined;
  } catch (error: any) {
    errorMessage.value = error.message;
  }
};

const updatePage = (newPageNum: number) => {
  page.value = newPageNum;
};
</script>
