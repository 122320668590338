import {
  PublicClientApplication,
  LogLevel,
  Configuration,
  RedirectRequest,
} from '@azure/msal-browser';
import config from '@/config';

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: config.CLIENT_ID,
    authority: config.AUTHORITY,
    redirectUri: config.REDIRECT_URL, // Must be registered as a SPA redirectURI on your app registration
    postLogoutRedirectUri: '/', // Must be registered as a SPA redirectURI on your app registration
    knownAuthorities: [config.AUTHORITY],
  },
  cache: {
    cacheLocation: 'localStorage',
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string) => {
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
      logLevel: LogLevel.Info,
    },
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const defaultRequest: RedirectRequest = {
  scopes: [config.BACKEND_SCOPE],
  extraScopesToConsent: config.EXTRA_SCOPES,
  redirectUri: window.location.origin,
};
