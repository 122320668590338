<template>
  <BootstrapAlert class="placeholder-glow my-0" variant="secondary">
    <div class="d-flex w-100">
      <div class="col-1 me-1">
        <h5><span class="align-self-start placeholder col-8"></span></h5>
      </div>
      <div class="col-11">
        <h5><span :class="`placeholder col-${placeholderNum(4, 6)}`"></span></h5>
        <p class="card-text">
          <span
            v-for="key in 5"
            :key="key"
            :class="`placeholder me-1 col-${placeholderNum(2, 6)}`"
          ></span>
        </p>
      </div>
    </div>
  </BootstrapAlert>
</template>
<script lang="ts" setup>
import {usePlaceholderNum} from '@/composables/placeholderNum';
const {placeholderNum} = usePlaceholderNum();
</script>
<style lang="scss" scoped>
.notification-date {
  font-size: 0.8rem;
  color: var(--bs-secondary-color);
}

.unread-notification {
  border-color: var(--bs-body-bg) !important;
}
</style>
