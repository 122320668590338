import {apolloPlatformAxiosInstance as axiosInstance} from './apolloPlatformAxiosInstance';

/**
 * User API
 */

export const apiGetUser = async () => {
  return await axiosInstance.get('users/me');
};

export const apiGetUserPermissions = async () => {
  return await axiosInstance.get('users/permissions');
};

export const apiPutUserRole = async (role: UserRoleUpdate) => {
  return await axiosInstance.put(`users/${role.user_id}/update_role`, role);
};

export const apiDeleteUserCustomerRelation = async (id: number, customerId: number) => {
  return await axiosInstance.put(`users/${id}/remove_customer`, {
    customer_id: customerId,
  });
};
