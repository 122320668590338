<template>
  <div class="pt-4">
    <div id="loading-container" class="loader-wrapper loader-wrapper-report pt-4 h-100">
      <div class="loader-index">
        <span></span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  id: string;
  core: string;
  corePath: string[] | '';
}>();
const router = useRouter();
const coresStore = useCoresStore();
const cores = computed(() => coresStore.cores);

onMounted(async () => {
  if (cores.value === null) {
    await coresStore.readPortalNavigation();
  } else {
    for (const core of cores.value) {
      if (core.id === parseInt(props.id)) {
        let newUrl = `/core/${core.slug}`;
        if (props.corePath) {
          newUrl += `/${props.corePath.join('/')}`;
        }
        router.replace(newUrl);
        return;
      }
    }
  }

  // Core not found
  router.push('/home');
});
</script>
