<template>
  <BootstrapAlert class="my-0" :variant="typeTranslation.get(props.type)">
    <div :id="`notification-content-${notification?.id}`" class="d-flex w-100">
      <Icon
        :icon="`material-symbols:${notificationIcon}`"
        class="flex-shrink-0 rounded-circle align-self-start mt-1 me-2"
      />
      <div class="flex-grow-1">
        <p class="text-wrap m-0">
          {{ notification?.description }}
        </p>
        <span v-if="notificationDate !== false" class="notification-date">
          {{ notificationDate }}
        </span>
        <BootstrapButton
          v-if="notification.data?.url"
          id="open"
          class="mt-2"
          size="sm"
          name="open"
          :variant="typeTranslation.get(props.type)"
          @click="$emit('openItem')"
        >
          View
        </BootstrapButton>
      </div>
      <IconButton
        v-if="notification?.unread === true"
        class="text-white align-self-start ms-2"
        icon-name="material-symbols:select-check-box"
        :variant="typeTranslation.get(props.type)"
        size="sm"
        @click="$emit('markItemAsRead', notification?.id)"
      />
    </div>
    <div
      v-if="notification?.unread === true"
      class="unread-notification position-absolute badge rounded-pill bg-danger border border-3"
      style="top: -0.3rem; right: -0.3rem; padding: 0.3rem"
      role="status"
    >
      <span class="visually-hidden">Unread notification</span>
    </div>
  </BootstrapAlert>
</template>
<script lang="ts" setup>
import {Notification} from '@/stores/apolloPlatform/notifications/notifications';
import {displayTimeStamp} from '@/timestamp';

const props = withDefaults(
  defineProps<{
    notification: Notification;
    type?: string;
  }>(),
  {type: 'info'},
);

defineEmits<{
  openItem: [];
  markItemAsRead: [id: number];
}>();

const userSettingsStore = useUserSettingsStore();
const language = computed(() => userSettingsStore.userSettings.language);

const typeTranslation = new Map([
  ['info', 'primary'],
  ['success', 'success'],
  ['warning', 'warning'],
  ['error', 'danger'],
]);

const notificationDate = computed(() => {
  const date = new Date(props.notification?.timestamp);
  if (isNaN(date?.getTime())) {
    return false;
  }
  return displayTimeStamp(date, language.value);
});

const notificationIcon = computed(() => {
  return props.notification?.data?.icon || 'image';
});
</script>
<style lang="scss" scoped>
.notification-date {
  font-size: 0.8rem;
  color: var(--bs-secondary-color);
}

.unread-notification {
  border-color: var(--bs-body-bg) !important;
}
</style>
