<template>
  <div ref="loc"></div>
</template>
<script lang="ts" setup>
import {getHoldingElement, teleportTarget} from '@/sphere/teleport';

const props = defineProps<{targetId: string}>();
const loc = ref<HTMLDivElement>();

const isHeldByMe = () => {
  return teleportTarget(props.targetId).parentElement === loc.value;
};

onMounted(() => {
  loc.value?.appendChild(teleportTarget(props.targetId));
});

onBeforeUnmount(() => {
  if (isHeldByMe()) {
    getHoldingElement().appendChild(teleportTarget(props.targetId));
  }
});
</script>
