<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 22.045 34.13">
    <g id="ekco-e-logo" transform="translate(-0.295 -0.451)">
      <path
        id="Path_6136"
        data-name="Path 6136"
        d="M22.339,8.965V.451H.313v14.6H2.955V3.084H19.744v3.24H7.474v8.726h13.6V12.417H10.116V8.965Z"
        class="st0"
      />
      <path
        id="Path_6137"
        data-name="Path 6137"
        d="M22.321,9.039V.534H.295v14.6H2.936V3.166H19.725v3.24H7.456v8.726h13.6V12.5H10.1V9.039Z"
        class="st0"
      />
    </g>
    <g id="Group_4082" data-name="Group 4082">
      <path
        id="Path_6138"
        data-name="Path 6138"
        d="M22.339,26.076v8.5H.313v-14.6H2.955V31.949H19.744V28.718H7.474V19.992h13.6v2.632H10.116v3.461H22.339Z"
        class="st0"
      />
      <path
        id="Path_6139"
        data-name="Path 6139"
        d="M22.321,26v8.5H.295v-14.6H2.936V31.875H19.725v-3.24H7.456V19.909h13.6v2.632H10.1V26H22.321Z"
        class="st0"
      />
    </g>
  </svg>
</template>
<style scoped>
.st0 {
  fill: #ffffff;
}
</style>
