<script>
/**
 * If we need to add any complexity to markdown-it
 * via plugins we can install vue-markdown-it:
 * https://github.com/JanGuillermo/vue3-markdown-it/blob/master/src/vue-markdown-it.js
 */
import MarkdownItContainer from 'markdown-it-container';
import MarkdownIt from 'markdown-it';

// https://github.com/markdown-it/markdown-it-container
const renderContainer = (tokens, idx, containerName) => {
  if (tokens[idx].nesting === 1) {
    // opening tag
    return `<div class="alert alert-${containerName}" role="alert">\n`;
  } else {
    // closing tag
    return '</div>\n';
  }
};

// https://github.com/ulfschneider/markdown-it-scrolltable
const renderTablesContainers = (md) => {
  md.renderer.rules.table_open = () => {
    return `<div class="scroll-table" style="overflow-x:auto;"><table>`;
  };
  md.renderer.rules.table_close = () => {
    return '</table></div>';
  };
};

const renderParagraphContainers = (md) => {
  md.renderer.rules.paragraph_open = () => {
    return `<p style="line-height: var(--bs-body-line-height);">`;
  };
  md.renderer.rules.paragraph_close = () => {
    return '</p>';
  };
};

//renderheadingcontainers should add id
const renderHeadingContainers = (md) => {
  md.renderer.rules.heading_open = (tokens, idx) => {
    return `<${tokens[idx].tag} id="${tokens[idx + 1].content.toLowerCase().replace(/ /g, '-')}">`;
  };
  md.renderer.rules.heading_close = (tokens, idx) => {
    return `</${tokens[idx].tag}>`;
  };
};

export default {
  props: {
    html: {
      type: Boolean,
      default: false,
    },
    linkify: {
      type: Boolean,
      default: false,
    },
    source: {
      type: String,
      default: '',
    },
  },
  /**
   * setup() hook required to for
   * returning vnodes, e.g h() function
   */
  setup(props) {
    const md = ref();
    const renderMarkdown = () => {
      let markdown = new MarkdownIt()
        .use(MarkdownItContainer, 'success', {
          render: (tokens, idx) => renderContainer(tokens, idx, 'success'),
        })
        .use(MarkdownItContainer, 'info', {
          render: (tokens, idx) => renderContainer(tokens, idx, 'info'),
        })
        .use(MarkdownItContainer, 'warning', {
          render: (tokens, idx) => renderContainer(tokens, idx, 'warning'),
        })
        .use(MarkdownItContainer, 'error', {
          render: (tokens, idx) => renderContainer(tokens, idx, 'error'),
        })
        .use(renderTablesContainers)
        .use(renderParagraphContainers)
        .use(renderHeadingContainers)
        .set({
          html: props.html,
          linkify: props.linkify,
        });

      md.value = markdown.render(props.source); // md to html
    };

    onMounted(() => renderMarkdown());
    onUpdated(() => renderMarkdown());

    return () => h('div', {innerHTML: md.value});
  },
};
</script>
