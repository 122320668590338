export function displayTimeStamp(date: Date, localeString: string = 'en-GB'): string {
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hourCycle: 'h23',
  };
  return date.toLocaleString(localeString, options);
}
