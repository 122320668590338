<template>
  <BootstrapNav
    class="px-3"
    :items="tabItems"
    :active-route="{title: activeTab}"
    @update:active-route="(tab: any) => $emit('update:activeTab', tab.title)"
  />
  <section class="h-100 overflow-y-auto custom-scrollbar pb-3" style="max-height: 70vh">
    <template v-if="loading">
      <NotificationItemLoading v-for="n in 3" :key="n" class="mx-3 mt-3" />
    </template>
    <div
      v-else-if="notifications.length <= 0"
      class="d-flex justify-content-center align-items-center"
    >
      <p class="my-5">No notifications found.</p>
    </div>
    <template v-else>
      <NotificationItem
        v-for="option in notifications"
        :key="option.id"
        class="mx-3 mt-3"
        :notification="option"
        :type="option.level"
        @open-item="$emit('openItem', option)"
        @mark-item-as-read="(id: number) => $emit('markItemAsRead', id)"
      />
    </template>
  </section>
  <div v-if="count > 1" class="d-flex align-items-end border-top p-3">
    <BootstrapPagination
      class="ms-auto"
      :count="count"
      :page="page"
      @update:page="(value: number) => $emit('update:page', value)"
    />
  </div>
</template>
<script lang="ts" setup>
import {Notification} from '@/stores/apolloPlatform/notifications/notifications';
const props = defineProps<{
  notifications: Notification[];
  tabs: string[];
  activeTab: string;
  count: number;
  page: number;
  loading: boolean;
}>();
defineEmits<{
  openItem: [value: Notification];
  markItemAsRead: [id: number];
  'update:activeTab': [id: number];
  'update:page': [value: number];
}>();
const tabItems = computed(() => props.tabs.map((tab) => ({title: tab})));
</script>
