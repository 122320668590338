<template>
  <router-link v-slot="slotProps" :to="link" :custom="custom">
    <slot v-bind="slotProps" />
  </router-link>
</template>
<script setup lang="ts">
import {Page} from '@/stores/apolloPlatform/cores/portalNavigation';

const props = defineProps<{page: Page; custom?: boolean; contentLink?: string | null}>();

const userSettingsStore = useUserSettingsStore();
const lang = computed(() => userSettingsStore.userSettings.language.substring(0, 2));
const link = computed(() => {
  let link: {name: string; hash?: string} = {name: `page-${props.page.id}-${lang.value}`};
  if (props.contentLink) {
    link.hash = `#${props.contentLink}`;
  }
  return link;
});
</script>
