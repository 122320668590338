<template>
  <SidebarLayout
    :collapsed="collapsed"
    :is-core-page="SidebarSquishPage"
    :is-mobile="isMobile"
    @close="$emit('close')"
  >
    <template #header>
      <MainSidebarHeader
        :collapsed="collapsed"
        @toggle="
          () => {
            $emit('toggle');
            $emit('update:menuSection', 'menu-default');
          }
        "
      />
    </template>
    <template #content>
      <div v-if="menuSectionModel === 'menu-settings'" class="h-100 d-flex flex-column">
        <div class="d-flex align-items-center px-3" style="padding-top: 0.875rem">
          <MenuIconButton icon="settings" :active="true" :is-home="false" />
          <h5 class="ps-3">{{ translate.translate(language, 'Settings') }}</h5>
          <div class="ps-3">
            <IconButton
              icon-name="material-symbols:chevron-left"
              @click="() => (menuSectionModel = 'menu-default')"
            />
          </div>
        </div>
        <p class="px-4 pt-2">{{ translate.translate(language, 'Preferences') }}</p>
        <SettingsListGroup
          class="settings-list-group my-3 px-3"
          data-testid="sidebar.expanded.foooter.show-feedback.button"
          :lang="language || 'en-US'"
          :theme="theme"
          :labs="labs"
          :has-labs-group="hasLabsGroup"
          @toggle-feedback="
            () => {
              $emit('close');
              $emit('toggle-feedback');
            }
          "
          @toggle-theme="(val: any) => $emit('toggle-theme', val)"
          @toggle-language="(val: any) => $emit('toggle-language', val)"
          @toggle-labs="$emit('toggle-labs')"
        />
        <template v-if="isMobile || isTablet">
          <CustomerMultiSelectSearch
            id="profile-menu-customer-select"
            data-testid="header.customer-select"
            style="max-width: 100%; max-height: 100%"
            class="px-3 mt-auto mb-3"
          />
        </template>
      </div>
      <div v-if="menuSectionModel === 'menu-default'">
        <CoreMenuListGroup
          :sidebar-collapsed="collapsed"
          :home-core-slug="homeCoreSlug"
          :active-core-node="activeCoreNode"
          :tree="filteredTree"
          :pages="pages"
          data-testid="sidebar.expanded.nav"
        />
      </div>
    </template>
    <template #footer>
      <section class="d-flex flex-column w-100 border-top">
        <ProfileMenu
          class="border-top"
          :name="fullName"
          email=""
          :lang="language || 'en-US'"
          :theme="theme"
          @toggle-theme="(val: any) => $emit('toggle-theme', val)"
          @toggle-language="(val: any) => $emit('toggle-language', val)"
          @logout="$emit('logout')"
        />
      </section>
    </template>
    <template #collapsed>
      <CoreMenuListGroup
        :sidebar-collapsed="true"
        :home-core-slug="homeCoreSlug"
        :active-core-node="activeCoreNode"
        :tree="filteredTree"
        :pages="pages"
        :is-mobile="isMobile"
        data-testid="sidebar.collapsed.nav"
      />
    </template>
  </SidebarLayout>
</template>
<script lang="ts" setup>
import {User} from '@/stores/apolloPlatform/user/user';
import {RouteLocation} from 'vue-router';
import {Page, TreeNav, TreeNodeCore} from '@/stores/apolloPlatform/cores/portalNavigation';
import translate from '@/translate';
import {MenuSection} from '@/pages/MainPage.vue';

const emits = defineEmits<{
  close: [];
  toggle: [];
  'toggle-feedback': [];
  'toggle-theme': [value: string];
  'toggle-language': [value: string];
  'toggle-labs': [];
  logout: [];
  'update:menuSection': [value: MenuSection];
}>();

const props = defineProps<{
  activeCoreNode?: TreeNodeCore;
  tree: TreeNav;
  homeCoreSlug: string;
  pages: Page[];
  user: User | null;
  collapsed: boolean;
  theme: string;
  language: string;
  labs: boolean;
  route: RouteLocation;
  menuSection?: MenuSection;
}>();

const menuSectionModel = computed({
  get: () => props.menuSection,
  set: (value: any) => emits('update:menuSection', value),
});

const {isMobile, isTablet} = useBsBreakpoints();

const SidebarSquishPage = computed(
  () =>
    props.activeCoreNode?.core.nav_type !== 'dashboard' ||
    props.route.fullPath.includes('/news/') ||
    props.route.fullPath.endsWith('/news') ||
    props.route.fullPath.endsWith('/team'),
);

watch(
  SidebarSquishPage,
  () => {
    if (SidebarSquishPage.value && props.collapsed === true && !isMobile.value) {
      emits('toggle');
    }
  },
  {immediate: true},
);

const fullName = computed(() => {
  if (props.user?.first_name && props.user?.last_name) {
    return `${props.user.first_name} ${props.user.last_name}`;
  } else {
    return '';
  }
});

const filteredTree = computed(() =>
  props.labs ? props.tree : props.tree.filter((node) => node.core.slug !== 'labs'),
);
const hasLabsGroup = computed(() => props.tree.some((node) => node.core.slug === 'labs'));
</script>
<style lang="scss" scoped>
.btn-home {
  background-color: #e2f9f1;
}
[data-bs-theme='dark'] .btn-home {
  background-color: #31424a;
}

[data-bs-theme='dark'] .btn-home * {
  color: #ace5ff;
}

.icon {
  color: var(--bs-tertiary-color);
}
</style>
