<template>
  <DialogLayout :open="props.open" @close="$emit('close')" @submit="submit">
    <template #header>Add Customers</template>
    <BootstrapFormControl
      v-model="searchModel"
      name="search"
      only-input
      class="mb-3"
      placeholder="Search for customers"
      :autocomplete="false"
      @enter="$emit('search', searchModel)"
      @addon-clicked="$emit('search', searchModel)"
    >
      <template #buttonContent>
        <Transition mode="out-in">
          <span
            v-if="searchLoading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
          <span v-else class="d-flex align-items-center">
            <Icon icon="material-symbols:search" />
          </span>
        </Transition>
      </template>
    </BootstrapFormControl>
    <div class="flex-grow-1 overflow-x-visible overflow-y-auto h-100 border rounded p-2">
      <div
        v-if="filteredOptions.length > 0"
        data-testid="team-page.invitaions.edit-dialog.customers.list"
      >
        <div v-for="(customer, index) in filteredOptions" :key="index" class="form-check">
          <input
            :id="`customerCheckbox-${customer.id}`"
            v-model="checkedIdsModel"
            class="form-check-input"
            :value="customer.id"
            type="checkbox"
            true-value="yes"
            false-value="no"
          />
          <label class="form-check-label" :for="`customerCheckbox-${customer.id}`">
            {{ customer.name }}
          </label>
        </div>
      </div>
      <div v-else>
        <div class="d-flex flex-column">
          <span class="mb-2">No customers found.</span>
          <span>Please try again using the search button.</span>
        </div>
      </div>
    </div>
    <template #buttons>
      <BootstrapButton
        name="submit-button"
        class="me-2"
        type="submit"
        data-testid="team-page.invitaions.edit-dialog.customers.button.submit"
        :disabled="loading"
        :loading="loading"
        @click.stop
      >
        Save
      </BootstrapButton>
      <BootstrapButton
        name="cancel-button"
        variant="secondary"
        data-testid="team-page.invitaions.edit-dialog.customers.button.cancel"
        @click="$emit('close')"
      >
        Cancel
      </BootstrapButton></template
    >
  </DialogLayout>
</template>
<script lang="ts" setup>
const props = defineProps<{
  customers: Customer[];
  invitations?: any[];
  open: boolean;
  loading?: boolean;
  searchLoading?: boolean;
}>();

const emit = defineEmits<{
  (event: 'close'): void;
  (event: 'submit', ids: number[]): void;
  (event: 'search', value: string): void;
}>();

const searchModel = ref('');
const checkedIdsModel = ref([]);

const options = computed(() => props.customers || []);
// All already selected invites customers
const selectedOptions = computed(() => props.invitations?.flatMap((i) => i.customers) || []);
/**
 * When selecting multiple invites, if a customer is not common to a single invite
 * Then the customer will be displayed as an option to add to multiple invites.
 * Remove customer from selectedOptions if it does not appear in each invitation once
 */
const uniqueSelectedOptions = computed(() =>
  selectedOptions.value.filter((o) => {
    const requiredCount = props.invitations?.length || 0;
    const optionCount = selectedOptions.value.filter((so) => so.id === o.id).length || 0;
    // Does the customer appear in both invitations? Then return is as already selected
    if (optionCount === requiredCount) return o;
  }),
);

const removedOptions = computed(() => {
  return options.value.filter((o) => {
    return !uniqueSelectedOptions.value.find((so) => o.id === so.id);
  });
});

const filteredOptions = computed(() =>
  removedOptions.value.filter((c) =>
    c.name.toLowerCase().includes(searchModel.value.toLowerCase()),
  ),
);

const submit = () => {
  if (checkedIdsModel.value.length === 0) emit('close');
  else {
    const customers: number[] = checkedIdsModel.value.filter((id) =>
      props.customers.find((c) => id === c.id),
    );
    emit('submit', customers);
  }
};

const isOpen = computed(() => props.open);
watch(isOpen, (newValue) => {
  if (newValue === false) {
    checkedIdsModel.value = [];
    searchModel.value = '';
  }
});
</script>
