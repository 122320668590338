<template>
  <DialogLayout :open="open" @close="$emit('close')">
    <template #header>{{ topText() }}</template>
    <span>{{ custString() }}</span>
    <template #buttons>
      <BootstrapButton name="cancel-button" variant="danger" class="me-2" @click="$emit('close')">
        {{ translateText('Cancel') }}
      </BootstrapButton>
      <BootstrapButton name="submit-button" @click="onSubmit">
        {{
          invitations.length === 1 && invitations[0].customers.length > 0
            ? translateText('Accept Invite')
            : translateText('Review Invites')
        }}
      </BootstrapButton>
    </template>
  </DialogLayout>
</template>

<script setup>
import DialogLayout from '@/components/shared/atoms/DialogLayout';
import BootstrapButton from '@/components/shared/bootstrap/BootstrapButton/BootstrapButton.vue';
import translate from '@/translate';
import {computed} from 'vue';

const {removeItem} = useLocalStorage();

const userSettingsStore = useUserSettingsStore();
const language = computed(() => userSettingsStore.userSettings.language);

const emits = defineEmits(['close', 'open', 'acceptInvite', 'reviewInvites']);

let props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  invitations: {
    type: Array,
    required: true,
  },
});

function custString() {
  if (props.invitations.length === 1) {
    let companyString = '';
    for (let i = 0; i < props.invitations[0].customers.length; i++) {
      companyString += props.invitations[0].customers[i].name + ', \n';
    }
    companyString = companyString.slice(0, -3);
    return companyString;
  }
  return;
}

async function acceptInvite(pk) {
  emits('acceptInvite', pk);
}

function reviewInvites() {
  emits('reviewInvites');
}

function onSubmit() {
  if (props.invitations.length === 1 && props.invitations[0].customers.length > 0) {
    acceptInvite(props.invitations[0]);

    // Explicitly removing this from local storage to ensure it isn't displayed
    // again after being accepted.
    removeItem('invitations');

    emits('close');
    return;
  }
  reviewInvites();
  emits('close');
  return;
}

function translateText(input) {
  return translate.translate(language.value, input);
}

function topText() {
  if (props.invitations.length === 1 && props.invitations[0].customers.length > 1) {
    return translateText('You have been invited to: ');
  } else if (props.invitations.length > 1) {
    return (
      translateText('You have ') + props.invitations.length + translateText(' pending invites')
    );
  } else {
    return translateText('You have pending invites');
  }
}
</script>
