export const useWidgetStore = defineStore('widget', {
  state: () => ({
    editWidgets: false,
    layout: [],
    loadedLayout: false,
    nextId: 0,
  }),
  actions: {
    toggleEditWidgets() {
      this.editWidgets = !this.editWidgets;
    },
    setLayout(layout) {
      this.layout = layout;
    },
    setLoadedLayout(value) {
      this.loadedLayout = value;
    },
    allocId() {
      return (this.nextId++).toString();
    },
  },
});
