<template>
  <section class="container-fluid h-100 pt-3">
    <MarkdownFile v-if="accessToken !== null" :file-config="fileConfig" :access-token="accessToken">
    </MarkdownFile>
  </section>
</template>

<script>
import MarkdownFile from '@/components/shared/atoms/MarkdownFile/MarkdownFile';
import {mapState} from 'pinia';
import {useApiInstance} from '@/composables/apiInstance.js';

export default {
  name: 'NewsItemPage',
  components: {
    MarkdownFile,
  },
  setup() {
    const {axiosApiInstance} = useApiInstance();
    return {axiosApiInstance};
  },
  data: () => ({
    fileConfig: {},
    accessToken: null,
  }),
  computed: {
    ...mapState(useCoresStore, ['pages']),
  },
  mounted() {
    if (this.pages !== null) {
      this.getNewsItem(this.$route.params.id);
    }
  },
  methods: {
    setPageTitle: function () {
      document.title = this.page !== undefined ? 'Ekco | ' + this.page.title : 'Ekco | Sphere';
    },

    async getNewsItem(id) {
      const config = {
        method: 'GET',
        url: `news/${id}`,
        params: {},
      };
      const response = await this.axiosApiInstance(config);
      this.fileConfig = response.data.token.documentConfig;
      this.accessToken = response.data.token.accessToken;
    },
  },
};
</script>
