import hotjar from '@/hotjar';
import config from '@/config';

export const useHotJarStore = defineStore('hotJar', {
  state: () => ({
    hotJarActive: false,
  }),
  actions: {
    setHotJar(hotJarActive: boolean) {
      this.hotJarActive = hotJarActive;
    },
    /**
     * Starts Hot Jar app
     */
    startHotJar() {
      if (this.hotJarActive === false) {
        try {
          hotjar(config.HOTJAR_ID, config.HOTJAR_SV);
          this.setHotJar(true);
        } catch (error) {
          console.error('Unable to start hotjar', error);
        }
      }
    },
  },
});
