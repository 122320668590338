export interface Notification {
  id: number;
  level: string;
  recipient_id: number;
  unread: boolean;
  actor_content_type_id: number;
  actor_object_id: string;
  verb: string;
  description: string;
  target_content_type_id: null | number | string;
  target_object_id: null | number | string;
  action_object_content_type_id: null | number | string;
  action_object_object_id: null | number | string;
  timestamp: string;
  public: boolean;
  deleted: boolean;
  emailed: boolean;
  data: any;
}

export interface NotificationResponse {
  notification_count: number;
  unread_count: number;
  data_type?: string;
  limit: number;
  offset: number;
  data: Notification[];
}

type notificationGroup = {
  [key: string]: NotificationResponse | undefined;
};

type NotificationType = 'Unread' | 'All Notifications';

export const useNotificationsStore = defineStore('notifications', () => {
  const notifications = ref<notificationGroup>({});
  const selectedType = ref<NotificationType>('Unread');

  const getNotificationsByType = computed(() => {
    return (type: string) => notifications.value[type];
  });

  const setNotifications = (type: string, notificationResponse: NotificationResponse) => {
    notifications.value[type] = notificationResponse;
  };

  const fetchNotifications = async (
    type: string = 'Unread',
    limit: number = 25,
    offset: number = 0,
  ): Promise<NotificationResponse | undefined> => {
    try {
      const {data} = await apiGetNotificationsList(limit, offset, type);
      setNotifications(type, data);
      return data;
    } catch (error) {
      throw apiErrorMessage(error);
    }
  };

  const fetchNotificationsWithResync = async (
    type: string = 'Unread',
    limit: number = 25,
    offset: number = 0,
  ): Promise<void> => {
    try {
      await fetchNotifications(type, limit, offset);
      if (
        notifications.value['Unread'] &&
        notifications.value['All Notifications'] &&
        notifications.value['Unread']?.notification_count !==
          notifications.value['All Notifications']?.notification_count
      ) {
        if (type === 'Unread') {
          notifications.value['All Notifications'] = undefined;
        } else if (type === 'All Notifications') {
          notifications.value['Unread'] = undefined;
        }
      }
    } catch (error) {
      throw apiErrorMessage(error);
    }
  };

  const markAllNotificationsAsRead = async (): Promise<void> => {
    try {
      await apiMarkAllNotificationsAsRead();

      if (notifications.value['Unread']) {
        notifications.value['Unread'].unread_count = 0;
        notifications.value['Unread'].data = [];
      }

      if (notifications.value['All Notifications']) {
        notifications.value['All Notifications'].unread_count = 0;
        notifications.value['All Notifications'].data.forEach((notification) => {
          notification.unread = false;
        });
      }
    } catch (error) {
      throw apiErrorMessage(error);
    }
  };

  const markNotificationAsRead = async (slug: number): Promise<void> => {
    try {
      if (
        notifications.value[selectedType.value]?.data.find(
          (notification) => notification.id === slug,
        )?.unread === false
      )
        return;

      await apiMarkNotificationAsRead(slug);

      if (notifications.value['Unread']) {
        if (notifications.value['Unread'].unread_count > 0) {
          notifications.value['Unread'].unread_count -= 1;
        }
        notifications.value['Unread'].data = notifications.value['Unread'].data.filter(
          (notification) => notification.id !== slug,
        );
      }

      if (notifications.value['All Notifications']) {
        if (notifications.value['All Notifications'].unread_count > 0) {
          notifications.value['All Notifications'].unread_count -= 1;
        }
        notifications.value['All Notifications'].data.forEach((notification) => {
          if (notification.id === slug) {
            notification.unread = false;
          }
        });
      }
    } catch (error) {
      throw apiErrorMessage(error);
    }
  };

  return {
    notifications,
    selectedType,
    getNotificationsByType,
    setNotifications,
    fetchNotifications,
    fetchNotificationsWithResync,
    markAllNotificationsAsRead,
    markNotificationAsRead,
  };
});
