<template>
  <section class="d-flex justify-content-between pt-3 pb-3">
    <router-link v-slot="{navigate}" :to="'/team/'" custom>
      <span
        class="d-flex align-items-center me-0s me-lg-2"
        style="cursor: pointer"
        data-testid="sidebar.expanded.foooter.user-profile"
        @click="navigate"
      >
        <Icon
          icon="material-symbols:account-circle"
          class="fs-4 flex-shrink-0 me-3"
          data-testid="sidebar.expanded.foooter.user-profile.img"
        />
        <div class="d-flex flex-column align-items-start">
          <div class="d-inline-block text-truncate" style="max-width: 200px">
            <span
              class="text-nowrap user-profile-name"
              data-hj-suppress
              data-testid="sidebar.expanded.foooter.user-profile.name"
              >{{ name }}</span
            >
          </div>
          <p
            class="d-inline-block text-truncate mb-0 font-roboto"
            style="max-width: 150px"
            data-hj-suppress
            data-testid="sidebar.expanded.foooter.user-profile.email"
          >
            {{ email }}
          </p>
        </div>
      </span>
    </router-link>

    <section>
      <MenuIconButtonPresentational
        id="logout"
        icon="logout"
        :title="translateText('Logout')"
        :active="false"
        @click="$emit('logout')"
      />
    </section>
  </section>
</template>

<script lang="ts" setup>
import translate from '@/translate';

interface Props {
  name: string;
  email: string;
  lang: string;
  theme: string;
}

const props = defineProps<Props>();

defineEmits(['logout']);

function translateText(input: string) {
  return translate.translate(props.lang, input);
}
</script>

<style lang="scss" scoped>
.user-profile-name {
  font-family: Rubik, sans-serif;
  font-size: 22px;
}
.button-logout {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  box-shadow: 0px 4px 12px #00000021;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  &:hover {
    background-color: var(--bs-border-color);
  }
}
[data-bs-theme='dark'] {
  .button-logout {
    background-color: transparent;
    box-shadow: none;
    &:hover {
      background-color: var(--bs-border-color);
    }
    button {
      color: #ffffff;
    }
  }
}
</style>
