<template>
  <label class="switch m-0" :for="id">
    <input :id="id" type="checkbox" :disabled="disabled" :checked="checked" />
    <div class="slider round" :style="sliderStyle">
      <div class="handle fs-6" :style="handleStyle">
        <slot></slot>
      </div>
      <div class="switch-text" data-testid="switch.text">
        {{ text }}
      </div>
    </div>
  </label>
</template>

<script lang="ts" setup>
interface Props {
  id: string;
  disabled?: boolean;
  scale?: number;
  text?: string;
  checked?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  scale: 0.75,
  text: '',
});

const slots = defineSlots();
const hasHandleContent = computed(() => slots.default || props.text.length > 0);

const sliderStyle = computed(() => {
  if (hasHandleContent.value === false && props.checked === true) {
    return 'background: var(--ekco-gradient);';
  }
});
const handleStyle = computed(() => {
  if (hasHandleContent.value) return 'background: var(--ekco-gradient);';
});
</script>

<style lang="scss" scoped>
.switch {
  display: inline-block;
  height: 34px;
  position: relative;
  width: 65px;
  scale: v-bind(scale);
}

.switch input {
  display: none;
}

.slider {
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

.slider::before {
  background-color: var(--bs-tertiary-bg);
  bottom: 4px;
  content: '';
  height: 26px;
  left: 4px;
  position: absolute;
  transition: 0.4s;
  width: 26px;
}

input + .slider {
  background-color: var(--bs-secondary-bg);
}

input:checked + .slider::before {
  transform: translateX(31px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round::before {
  border-radius: 50%;
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  stroke: #2b2b2b;
}

.handle {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 4px;
  left: 4px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.4s;
  color: var(--bs-white);
  z-index: 1;
}

input:checked + .slider .handle {
  transform: translateX(31px);
}

.switch-text {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--bs-body-color);
}

input:checked + .slider .switch-text {
  right: auto;
  left: 10px;
}
</style>
