<template>
  <img class="logo-ekco for-light" src="@/assets/images/logo/logo.png" alt="Ekco Logo" />
  <img class="logo-ekco for-dark" src="@/assets/images/logo/logo_dark.png" alt="Ekco Logo" />
</template>
<style lang="scss" scoped>
.logo-ekco {
  height: 32px;
  width: auto;
}
.for-light {
  display: block;
}
.for-dark {
  display: none;
}

[data-bs-theme='dark'] {
  .for-light {
    display: none;
  }
  .for-dark {
    display: block;
  }
}
</style>
