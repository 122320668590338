<template>
  <div
    class="alert fade show d-flex align-items-center"
    :class="[`alert-${variant}`, {'alert-dismissible fade show': dismissible === true}]"
    role="alert"
  >
    <slot></slot>
    <button
      v-if="dismissible === true"
      type="button"
      class="btn-close"
      data-bs-dismiss="alert"
      aria-label="Close"
      @click="$emit('dismiss')"
    />
  </div>
</template>
<script setup>
import Alert from 'bootstrap/js/dist/alert.js';

defineProps({
  variant: {
    type: String,
    default: 'primary',
    validators: (value) =>
      ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'].includes(
        value,
      ),
  },
  dismissible: {
    type: Boolean,
    default: false,
  },
});

defineEmits(['dismiss']);

onMounted(() => {
  const element = document.querySelector('.alert');
  new Alert(element);
});
</script>
<style lang="scss" scoped>
.alert {
  --bs-alert-border-color: transparent;
}
</style>
