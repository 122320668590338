// Nested response types

import {createCoreRoute} from '@/stores/apolloPlatform/cores/portalNavRoute';
import {
  Core,
  NavigationViewSet,
  Page,
  TreeNav,
  TreeNodeCore,
  TreeNodePage,
  makeCoreNavigationTree,
} from '@/stores/apolloPlatform/cores/portalNavigation';

function orderCores(cores: Core[], homeSlug: string): Core[] {
  return cores.toSorted((a, b) => {
    // Home always on top
    const aHome = a.slug === homeSlug;
    const bHome = b.slug === homeSlug;
    const homeOrder = Number(bHome) - Number(aHome);
    if (homeOrder !== 0) return homeOrder;
    // Order rest by the order field
    return a.order - b.order;
  });
}

function orderPages(pages: Page[]) {
  return pages.toSorted((a, b) => {
    return a.order - b.order;
  });
}

export const useCoresStore = defineStore('cores', {
  state: () => ({
    navTree: undefined as TreeNav | undefined,
    cores: null as Core[] | null, // api/portal_navigation/
    homeCoreSlug: null as string | null,
    pages: null as Page[] | null, // api/pages/
    removeNavHook: undefined as (() => void) | undefined,
  }),
  getters: {
    getHomeCoreSlug: (state) => state.homeCoreSlug || '',
    getPages: (state) => state.pages || [],
  },
  actions: {
    async setPortalNavigation(navigationViewSet: NavigationViewSet) {
      const slug = navigationViewSet.home_core_slug;
      const sortedCores = orderCores(navigationViewSet.cores, slug);
      const pages = orderPages(navigationViewSet.pages);
      const navTree = makeCoreNavigationTree(sortedCores, pages);

      const coreRoutes = navTree.map((coreNode) => createCoreRoute(coreNode));

      if (this.router.hasRoute('core-loading')) {
        this.router.removeRoute('core-loading');
      }
      if (this.router.hasRoute('core-dynamic')) {
        // Replace old core-dynamic with new
        this.router.removeRoute('core-dynamic');
      }
      this.router.addRoute('core-base', {
        name: 'core-dynamic',
        path: '',
        children: coreRoutes,
      });

      this.homeCoreSlug = slug;
      this.cores = reactive(sortedCores);
      this.pages = reactive(pages);
      this.navTree = reactive(navTree);

      const path = this.router.currentRoute.value.fullPath;

      // See where we end up after the router.replace call and expand those nodes
      const destRoute = this.router.resolve(path);
      if (destRoute.meta.coreNode) {
        this.expandNodes(destRoute.meta.coreNode, destRoute.meta.activePageNode);
      }
      this.registerNavigationCallback(); // For future navigations
      await this.router.replace(path);
    },
    registerNavigationCallback() {
      if (this.removeNavHook) return; // Already registered
      this.removeNavHook = this.router.beforeEach((to) => {
        this.expandNodes(to.meta.coreNode, to.meta.activePageNode);
      });
    },
    async readPortalNavigation() {
      try {
        const {data} = await apiGetPortalNavigation();
        await this.setPortalNavigation(data);
      } catch (error) {
        throw apiErrorMessage(error);
      }
    },
    expandNodes(newCore: TreeNodeCore | undefined, newPage: TreeNodePage | undefined) {
      if (newPage) {
        // Navigate from newPage all the way up to the root, closing every item
        // along the way except for the one that leads to newPage.

        let node: TreeNodePage | TreeNodeCore = newPage;
        while (node) {
          const toDestination = node;
          if (node.node_type !== 'PageNode') {
            break;
          }
          node = node.parent;

          for (const child of node.children) {
            reactive(child).collapsed = toRaw(toDestination) !== toRaw(child);
          }
        }
      }
      if (newCore && this.navTree) {
        // Collapse all but the active
        for (const coreNode of this.navTree) {
          reactive(coreNode).collapsed = coreNode.core.id !== newCore.core.id;
        }
      }
    },
    async switchLanguage() {
      /**
       * This action should be called when the user language has changed.
       * @see {@link stores/apolloPlatform/userSettings/userSettings.ts}
       * Re-fetch using the new Accept-Language header. This is done in the Axios request interceptor.
       * @see {@link api/apolloPlatform/apolloPlatformAxiosInstance.ts}
       */
      await this.readPortalNavigation();
    },
  },
});
