<template>
  <div class="banner">
    <div class="container-fluid">
      <div class="row align-items-start align-items-md-center justify-content-between">
        <div class="col-12 col-md-auto d-flex justify-content-center mb-3 mb-md-0">
          <div class="d-flex align-items-center">
            <slot name="text"></slot>
          </div>
        </div>
        <div class="col-12 col-md-6 d-flex">
          <div class="d-flex align-items-center">
            <slot name="description"></slot>
          </div>
        </div>
        <div class="col-12 col-md-3 d-flex align-items-center justify-content-center">
          <slot name="buttons"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.banner {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to right, #3a7ed3, #7bb4fb);
  color: #f4f8fd;
  z-index: 8;
  padding: 10px;
  box-sizing: border-box;
}

[data-bs-theme='dark'] .banner {
  background: linear-gradient(to right, var(--bs-body-bg), var(--bs-secondary-bg));
  color: #f4f8fd;
}

.container-fluid {
  padding: 0;
  margin: 0;
}

.slot-text img {
  max-height: 60px; /* Adjust the height as desired */
}
</style>
