<template>
  <div v-if="selectedCustomerReady" class="search-bar-container search-element">
    <BootstrapFormControl
      v-model="query"
      name="search-panel"
      placeholder="Type to search..."
      class="flex-grow-1 search-input"
      :class="{'search-input-focus-override': panelOpen || query !== ''}"
      only-input
      size="sm"
      clear
      @enter="onReturnKey"
      @addon-left-clicked="onReturnKey"
      @focus="onFocus"
    >
      <template #buttonContentLeft>
        <Icon icon="material-symbols:search" class="search-icon" style="font-size: 1.25rem" />
      </template>
    </BootstrapFormControl>
    <div v-if="panelOpen && query !== ''" class="search-panel card mt-1">
      <header class="g-0 d-flex flex-row w-100">
        <div
          :class="{
            col: true,
            'page-filter-button': true,
            'filter-button': true,
            'active-filter': filter === undefined,
          }"
          @click="() => updateFilter(undefined)"
        >
          Pages
        </div>
        <div
          :class="{
            col: true,
            'document-filter-button': true,
            'filter-button': true,
            'active-filter': filter === ContentType.Document,
          }"
          @click="() => updateFilter(ContentType.Document)"
        >
          Documents
        </div>
        <div
          :class="{
            col: true,
            'report-filter-button': true,
            'filter-button': true,
            'active-filter': filter === ContentType.Report,
          }"
          @click="() => updateFilter(ContentType.Report)"
        >
          Reports
        </div>
      </header>
      <div class="search-body card-body p-0">
        <DefaultSpinner v-if="loading" class="loading-wrapper" />
        <div v-else ref="resultsWrapper" class="results-wrapper custom-scrollbar">
          <ul v-if="resultLinks.length > 0" class="my-3 p-0">
            <li v-for="link in resultLinks" :key="link.id" class="mb-1 px-3">
              <Icon :icon="getIcon(link.type)" :inline="true" />
              <PageLink
                class="link-wrapper search-element"
                :page="link.pages[0]"
                :content-link="link.contentLink"
                @click="closeSearchPanel"
              >
                <div class="card-text">{{ formatTitle(link) }}</div>
              </PageLink>
            </li>
          </ul>
          <div v-else class="card-body">No search results to display</div>
        </div>
        <div v-if="!loading && !loadingMore && moreResultsAvailable" class="card-footer">
          <BootstrapButton
            class="search-element more-button"
            variant="ghost"
            size="sm"
            @click="loadMore"
          >
            Load More
          </BootstrapButton>
        </div>
        <DefaultSpinner v-else-if="loadingMore" />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import {ContentType, SearchResult} from '@/api/apolloPlatform/search';
import {useDebounceFn} from '@vueuse/core';
import {Core, Page} from '@/stores/apolloPlatform/cores/portalNavigation';

const PAGE_SIZE = 10;
const TITLE_LENGTH_LIMIT = 50;

interface ResultLink {
  id: number;
  contentLink: string | null;
  pages: Page[];
  title: string;
  type: ContentType;
  core: Core;
}

const resultsWrapper = ref<HTMLDivElement | null>(null);

const customersStore = useCustomersStore();
const coresStore = useCoresStore();

const query = ref('');
const panelOpen = ref(false);

// Loading results after a query or filter change
const loading = ref(false);

// Loading another page of results
const loadingMore = ref(false);

const resultLinks = ref<ResultLink[]>([]);
const filter = ref<ContentType | undefined>(undefined);
const page = ref(1);
const totalresults = ref(0);

// Maps the SearchResult list to a ResultLink list.
// This will also filter out any results which aren't found in the navigation tree.
function searchResultsToLinks(results: SearchResult[]): ResultLink[] {
  return results.reduce((filtered: ResultLink[], sr: SearchResult) => {
    const pages: Page[] = [];

    let page = getPageFromNavTree(sr.id);

    // Skip this result if the page isn't found
    if (page === undefined) {
      return filtered;
    }

    // Build the list of pages in the link path
    pages.push(page);
    while (page?.parent_id) {
      page = getPageFromNavTree(page.parent_id);
      if (page !== undefined) {
        pages.push(page);
      }
    }

    // Find the core the entire path is under
    let core;
    if (page?.core_id) {
      core = coresStore.cores?.find((c) => c.id === page.core_id);
    }

    // If no core is found, skip this result
    if (core === undefined) {
      return filtered;
    }

    filtered.push({
      id: sr.id,
      contentLink: sr.content_link,
      pages,
      title: sr.title,
      type: sr.type,
      core,
    });
    return filtered;
  }, []);
}

const selectedCustomerReady = computed(() => customersStore.selectedCustomer !== undefined);
const moreResultsAvailable = computed(() => resultLinks.value.length < totalresults.value);

const onQueryUpdated = useDebounceFn(() => {
  doSearch();
}, 1000);

watch(query, (newValue, oldValue) => {
  // No change to the search query, nothing to do
  if (newValue === oldValue) {
    return;
  }

  // No query entered - close the panel
  if (newValue === '') {
    panelOpen.value = false;
    return;
  }

  onQueryUpdated();
});

watch(filter, (newValue, oldValue) => {
  // No change to the search query, nothing to do
  if (newValue === oldValue || !panelOpen.value) {
    return;
  }

  doSearch();
});

async function doSearch() {
  loading.value = true;
  panelOpen.value = true;
  page.value = 1;
  resultLinks.value = [];
  const response = await apiSearch(query.value, page.value, PAGE_SIZE, filter.value);
  resultLinks.value = searchResultsToLinks(response.results);
  totalresults.value = response.total_results;
  loading.value = false;
}

function onReturnKey() {
  if (query.value) {
    doSearch();
  }
}

async function onFocus() {
  if (query.value) {
    panelOpen.value = true;

    // Scroll the newest search results into view
    await nextTick();
    const resultsDiv = resultsWrapper.value!;
    resultsDiv.scrollTop = resultsDiv.scrollHeight;
  }
}

function updateFilter(newValue: ContentType | undefined) {
  // No change to the filter, do nothing
  if (newValue === filter.value) {
    return;
  }
  filter.value = newValue;
  doSearch();
}

function closeSearchPanel() {
  panelOpen.value = false;
  loading.value = false;
}

function getPageFromNavTree(pageId: number): Page | undefined {
  return coresStore.pages?.find((p) => p.id === pageId);
}

async function loadMore() {
  loadingMore.value = true;
  page.value += 1;
  const response = await apiSearch(query.value, page.value, PAGE_SIZE, filter.value);
  resultLinks.value = [...resultLinks.value, ...searchResultsToLinks(response.results)];

  // Scroll the newest search results into view
  await nextTick();
  const resultsDiv = resultsWrapper.value!;
  resultsDiv.scrollTop = resultsDiv.scrollHeight;

  loadingMore.value = false;
}

function getIcon(linkType: string) {
  switch (linkType) {
    case 'document':
      return 'material-symbols:docs-outline';
    case 'report':
      return 'material-symbols:bar-chart';
    case 'file':
      return 'material-symbols:folder-outline';
    default:
      return 'material-symbols:docs-outline';
  }
}

// Creates a formatted link title from the list of pages and the core
function formatTitle(link: ResultLink): string {
  const pageTitles: string[] = link.pages
    .toReversed()
    .map((p) => p.title)
    .map(capitalise);
  const coreTitle: string = link.core.title;

  // All links will start at the core, and continue down the core-category-page hierarchy
  const titleParts = [coreTitle, ...pageTitles];

  // For file manager links, we also need to factor in the content link field
  if (link.type === ContentType.File && link.contentLink) {
    const linkParts = link.contentLink.split('/');
    const formattedParts = linkParts.map(capitalise);
    titleParts.push(...formattedParts);
  }

  // If the formatted title is too long, shorten it
  let formattedTitle = titleParts.join(' / ');
  if (formattedTitle.length > TITLE_LENGTH_LIMIT) {
    const lastIndex = titleParts.length - 1;
    formattedTitle = [titleParts[0], titleParts[1], '...', titleParts[lastIndex]].join(' / ');
  }

  return formattedTitle;
}

// Return the string with its first letter captialized
function capitalise(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

window.addEventListener('click', ({target}: MouseEvent) => {
  if (!(target as HTMLElement).closest('.search-element')) {
    closeSearchPanel();
  }
});
</script>

<style lang="scss" scoped>
.search-bar-container {
  position: relative;
  min-width: 10rem;
  max-width: 35rem;

  input {
    width: 100%;
  }

  .search-panel {
    position: absolute;
    width: 105%;

    header {
      padding-top: 0.3em;
      width: 50%;
    }
    div.search-body {
      border-top: 1px solid var(--bs-border-color);
    }

    li {
      list-style: none;
    }

    li:hover {
      background-color: color-mix(in srgb, var(--bs-blue-500) 7.5%, transparent);
    }

    .link-wrapper {
      display: inline-block;
      margin-left: 1em;
      font-size: 0.8rem;
    }

    .results-wrapper {
      overflow-y: auto;
      max-height: 15rem;
    }

    .filter-button {
      text-align: center;
      padding-bottom: 0.3em;
    }

    .filter-button:hover {
      cursor: pointer;
    }

    .active-filter {
      font-weight: bold;
      background-image: var(--ekco-gradient);
      background-position: 0% 100%;
      background-size: 100% 3px;
      background-repeat: no-repeat;
      border-bottom: 0px;
    }

    .more-button {
      color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
    }
  }
}

.search-input {
  background-color: transparent !important;
  border-radius: var(--bs-border-radius-sm) !important;
  border: var(--bs-border-width) solid transparent;
  transition:
    background-color 0.3s ease,
    border 0.3s ease,
    box-shadow 0.3s ease;

  :deep(input) {
    background-color: transparent !important;
    border: 0 !important;
  }

  :deep(input:focus) {
    background-color: transparent !important;
    box-shadow: none !important;
  }

  :deep(button) {
    background-color: transparent !important;
    border: 0 !important;
    color: var(--bs-body-color) !important;
  }
}

.search-input:focus-within,
.search-input-focus-override {
  background-color: var(--bs-body-bg) !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  box-shadow: var(--bs-box-shadow-sm) !important;
}
</style>
