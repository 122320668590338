<template>
  <div class="relative d-flex flex-column">
    <label v-if="!onlyInput && name" :for="name" class="form-label" data-hj-suppress>
      {{ name }}
      <span v-if="required === true" class="text-danger">*</span>
    </label>
    <div :class="{'input-group': inputGroup === true}">
      <multiselect
        :id="name"
        ref="multiSelect"
        :model-value="props.modelValue"
        :aria-label="ariaLabel || name"
        class="form-control p-0"
        :class="[
          {'vue-multiselect-override': inputGroup === true},
          {'is-invalid': error.length > 0},
          {'is-valid': error.length === 0 && serverError === true},
        ]"
        :aria-required="required"
        :allow-empty="!required"
        :close-on-select="closeOnSelect"
        :clear-on-select="false"
        :label="label"
        :name="name"
        :limit="limit"
        :max-height="maxHeight"
        :multiple="multiple"
        :taggable="taggable"
        :options="options"
        :placeholder="placeholder"
        :preserve-search="true"
        :show-labels="false"
        :track-by="trackBy"
        :loading="loading"
        data-hj-suppress
        @update:model-value="emit('update:modelValue', $event)"
        @search-change="(value: string) => handleSearch(value)"
        @tag="(searchQuery: string) => $emit('tag', searchQuery)"
        @select="(option: T) => $emit('select', option)"
        @remove="(option: T) => $emit('remove', option)"
      >
        <template #option="{option}">
          <slot data-hj-suppress name="option" :option="option" />
        </template>
        <template #noResult><slot name="noResult" /></template>
        <template #afterList><slot class="afterList" name="afterList" /></template>
      </multiselect>
    </div>
    <span v-if="error" role="alert" aria-atomic="true" class="text-danger invalid-feedback d-block">
      {{ error }}
    </span>
  </div>
</template>
<script setup lang="ts" generic="T">
import Multiselect from 'vue-multiselect';

interface Props {
  ariaLabel?: string;
  inputGroup: boolean;
  limit?: number;
  placeholder?: string;
  maxHeight?: number;
  // T | null when multiple is false, T[] when it is true
  modelValue: T | null | T[];
  name: string;
  options: T[];
  trackBy?: string;
  loading?: boolean;
  multiple?: boolean;
  taggable?: boolean;
  label?: string;
  required?: boolean;
  closeOnSelect?: boolean;
  tagPlaceholder?: string;
  error?: string;
  serverError?: boolean;
  onlyInput?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  ariaLabel: '',
  placeholder: 'Select or search for an option',
  limit: 10,
  maxHeight: 100,
  trackBy: undefined,
  loading: false,
  multiple: false,
  taggable: false,
  label: undefined,
  required: false,
  closeOnSelect: true,
  tagPlaceholder: 'Press enter to create a tag',
  error: '',
  serverError: false,
  onlyInput: false,
});

const emit = defineEmits<{
  'update:modelValue': [value: any];
  search: [value: string];
  tag: [searchQuery: string];
  select: [option: T];
  remove: [option: T];
}>();

/** @see {@link https://vue-multiselect.js.org/#sub-programmatic-control} */
const multiSelect = ref<ComponentPublicInstance | null>(null);

const options = computed(() => props.options || []);

const handleSearch = async (value: string) => {
  multiSelect.value?.$el?.focus();
  emit('search', value);
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
.input-group > .multiselect__tags {
  /* top-left | top-right | bottom-right | bottom-left */
  border-radius: 5px 0 0 5px;
}

:deep(.multiselect__tags) {
  background-color: var(--bs-secondary-bg) !important;
  border: 0;
  border-radius: 5px !important;
  color: var(--bs-body-color) !important;
  ::placeholder {
    color: var(--bs-body-color) !important;
  }
}

:deep(.multiselect__content-wrapper) {
  margin-top: 1px;
  background-color: transparent !important;
  border-color: var(--bs-border-color) !important;
  border: 1;
  color: var(--bs-body-color) !important;
  ::placeholder {
    color: var(--bs-body-color) !important;
    font-size: inherit;
  }
}

:deep(.multiselect__content) {
  border-radius: 5px !important;
  border-color: var(--bs-border-color) !important;
  color: var(--bs-body-color) !important;
  background-color: var(--bs-secondary-bg) !important;
}

:deep(.multiselect__content) aside {
  position: sticky;
  bottom: 0;
}

:deep(.multiselect__input),
:deep(.multiselect__single) {
  background: none !important;
  background-color: var(--bs-secondary-bg) !important;
}

:deep(.btn-outline-secondary) {
  background-color: var(--bs-secondary-bg);
  border-color: var(--bs-border-color);
}

:deep(.multiselect__placeholder) {
  padding: 0;
  font-size: inherit;
}

:deep(.multiselect__option),
:deep(.multiselect__option) > span {
  white-space: normal;
}

:deep(.multiselect__option--highlight) {
  background-color: var(--bs-primary) !important;
}

:deep(.multiselect__spinner) {
  background-color: var(--bs-secondary-bg);
}

:deep(.multiselect__spinner::before),
:deep(.multiselect__spinner::after) {
  border-color: var(--bs-primary) transparent transparent;
}
</style>
