import {apolloPlatformAxiosInstance as axiosInstance} from './apolloPlatformAxiosInstance';

/**
 * Customers Endpoints
 */

export const apiGetCustomers = async (search?: string) => {
  if (search !== null && search !== undefined && search !== '') {
    return await axiosInstance.get('customer_search', {params: {search}});
  }
  return await axiosInstance.get('customers');
};

export const apiGetCustomer = async (id: number) => {
  return await axiosInstance.get(`customers/${id}`);
};
