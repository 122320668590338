<template>
  <nav v-if="props.count > 1" aria-label="Data table navigation" class="d-flex align-items-center">
    <ul class="pagination pagination-sm mb-0">
      <!-- Chevron -->
      <li
        :class="[{disbled: currentPage === 1}, 'page-item']"
        @click="currentPage > 1 && (currentPage = currentPage - 1)"
      >
        <a class="page-link" aria-label="Previous">
          <Icon icon="material-symbols:chevron-left" :inline="true" />
        </a>
      </li>

      <!-- Start -->
      <li
        :class="[{active: currentPage === pages[0]}, 'page-item']"
        @click="currentPage = pages[0]"
      >
        <a class="page-link">{{ pages[0] }}</a>
      </li>

      <!-- Ellipses -->
      <li v-if="startEllipses" class="page-item disabled"><a class="page-link">...</a></li>

      <!-- Pages -->
      <li
        v-for="pageItem in filterPages"
        :key="pageItem"
        :class="[{active: currentPage === pageItem}, 'page-item']"
        @click="currentPage = pageItem"
      >
        <a class="page-link">{{ pageItem }}</a>
      </li>

      <!-- Ellipses -->
      <li v-if="endEllipses" class="page-item disabled"><a class="page-link">...</a></li>

      <!-- End -->
      <li
        :class="[{active: currentPage === pages[props.count - 1]}, 'page-item']"
        @click="currentPage = pages[props.count - 1]"
      >
        <a class="page-link">{{ pages[props.count - 1] }}</a>
      </li>

      <!-- Chevron -->
      <li
        :class="[{disabled: currentPage === props.count}, 'page-item']"
        @click="currentPage < count ? (currentPage = currentPage + 1) : ''"
      >
        <a class="page-link" aria-label="Next">
          <Icon icon="material-symbols:chevron-right" :inline="true" />
        </a>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    count: number;
    page?: number;
  }>(),
  {page: 1},
);

const emits = defineEmits<{'update:page': [cursor: number]}>();

const currentPage = ref(props.page || 1);

const pages = computed(() => {
  const pages = [];
  for (let i = 1; i <= props.count; i++) {
    pages.push(i);
  }
  return pages;
});

const filterPages = computed(() => {
  // page count 5 + 2 for ellipses
  const visiblePageCount = 5;

  if (props.count < 8) {
    return pages.value.slice(1, props.count - 1);
  }
  if (currentPage.value < 5) {
    return pages.value.slice(1, visiblePageCount);
  } else if (currentPage.value > props.count - 2) {
    return pages.value.slice(props.count - visiblePageCount, props.count - 1);
  } else {
    if (currentPage.value >= props.count - 2) {
      return pages.value.slice(currentPage.value - 3, currentPage.value + 1);
    } else {
      return pages.value.slice(currentPage.value - 2, currentPage.value + 1);
    }
  }
});

const startEllipses = computed(() => currentPage.value > 4 && 2 !== filterPages.value[0]);
const endEllipses = computed(
  () =>
    currentPage.value < props.count - 2 &&
    props.count - 1 !== filterPages.value[filterPages.value.length - 1],
);

watch(currentPage, (newValue, oldValue) => {
  if (newValue !== oldValue) emits('update:page', currentPage.value);
});

watch(
  () => props.page,
  () => {
    currentPage.value = props.page || 1;
  },
  {immediate: true},
);
</script>
<style lang="scss" scoped>
.page-item {
  color: var(--bs-body-color);
}
.page-link {
  cursor: pointer;
}
</style>
