import {apolloPlatformAxiosInstance as axiosInstance} from './apolloPlatformAxiosInstance';

/**
 * Invitations API
 */

export const apiPostInvitation = async (
  invitation: InvitationCreate,
): Promise<AxiosResponse<Invitation>> => {
  return await axiosInstance.post('invitations/', invitation);
};

export const apiGetInvitations = async (
  cursor?: string,
): Promise<AxiosResponse<GenericCursorFn<Invitation>>> => {
  return await axiosInstance.get('invitations', {params: {cursor}});
};

/**
 * The PUT endpoint is decorated w/ a number of Django @actions
 * @see {@link apollo_platform/apps/invitations/api/views.py}
 */

export type InvitationAction =
  | 'accept'
  | 'decline'
  | 'add_customer'
  | 'remove_customer'
  | 'add_group'
  | 'remove_group';

// prettier-ignore
export type DataOfAction<T> =
    T extends 'accept' | 'decline' ? undefined :
    T extends 'add_customer' ? {customer_id: number; role: string} :
    T extends 'remove_customer' ? {customer_id: number} :
    T extends 'add_group' | 'remove_group' ? {group_id: number} :
    undefined;

export const apiPutInvitation = async <T extends InvitationAction>(
  id: number,
  action: T,
  data: DataOfAction<T>,
): Promise<AxiosResponse<Invitation>> => {
  return await axiosInstance.put(`invitations/${id}/${action}`, data);
};
